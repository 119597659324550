import React from 'react'
import './ProjectPages.css'

export default function WritingandDrawingBot() {

    const WritingandDrawingBotcover = [
        {
            src: '/Projects/Projects/Coding.jpg',
            alt: 'WritingandDrawingBot',
        },
    ]

    return (
        <div>
            <div>
                {WritingandDrawingBotcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Writing and Drawing Bot</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Create a machine capable of writing and drawing autonomously, combining robotics and
                        creativity in one project.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Writing and Drawing Machine project involves creating a robotic system capable of
                        autonomously producing written or drawn output. It combines mechanical components,
                        such as stepper motors and linear actuators, with software algorithms for precise
                        control. This project fosters creativity and explores intersections between robotics and
                        art. Features may include programmable patterns, variable stroke thickness, and image
                        reproduction.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It&#39;s a great teaching tool that provides real-world knowledge
                                about robotics, computer science, mechanical engineering, and principles of artistic
                                design.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Creative Expression</b><br />
                                By utilising technology to facilitate artistic expression, The
                                Writing and Drawing Robot encourages creativity and inventiveness in both
                                enthusiasts and students. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Development of Skills</b><br />
                                Assisting with a writing and drawing robot fosters
                                collaboration and project management skills while improving comprehension of
                                robotics, programming, and mechanical design. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Writing and Drawing Robot</span> project </p>
                        <p>has a wide future reach and great potential in
                            many different fields</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Art and Design</b></span></p>
                    <p className='pt-2'>It can result in new developments in automated artistic creation
                        that open up possibilities for robotic and digital art installations.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>business Products</b></span></p>
                    <p className='pt-2'>Robots that can write and draw can be created for use in
                        calligraphy, custom signage, and automated graffiti clearance, among other
                        business uses.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Assistive technology</b></span></p>
                    <p className='pt-2'>By making adjustments to assistive technology, people
                        with impairments can receive assistance with writing or drawing chores,
                        increasing their independence and accessibility.</p>
                </div>
            </div>
        </div>
    )
}
