import React from 'react'
import './ProjectPages.css'

export default function IoTbasedHomeAutomation() {

    const IoTbasedHomeAutomationcover = [
        {
            src: '/Projects/Projects/IOT.jpg',
            alt: 'IoTbasedHomeAutomation',
        },
    ]

    return (
        <div>
            <div>
                {IoTbasedHomeAutomationcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>IoT based Home Automation</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Develop a home automation system using IoT technology, enabling remote control and
                        monitoring of household devices.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The IoT Based Home Automation project focuses on designing a smart home system that
                        enables remote monitoring and control of household devices. It utilizes Internet of
                        Things (IoT) technology to connect sensors, actuators, and appliances to a centralized
                        network. Through a mobile or web interface, users can manage lighting, temperature,
                        security, and energy consumption. This project promotes energy efficiency,
                        convenience, and home security.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Convenience</b><br />
                                By enabling remote control and monitoring of household appliances,
                                IoT-based home automation improves comfort and efficiency while providing
                                convenience.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Energy Efficiency</b><br />
                                Lowering energy use and utility bills can result from automated
                                lighting, heating, and appliance control.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Security</b><br />
                                Including security measures like motion detectors, door sensors, and
                                surveillance cameras improves the safety and comfort of a home. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>IoT-based Home Automation</span> project </p>
                        <p>has a broad future scope and great potential in a number
                            of disciplines, including</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Smart Cities</b></span></p>
                    <p className='pt-2'>By optimising resource utilisation and enhancing quality of life, the project&#39;s
                        insights can aid in the construction of smart city infrastructure.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Healthcare</b></span></p>
                    <p className='pt-2'>IoT technology adaptations can improve access to medical services by enabling
                        personalised healthcare solutions and remote patient monitoring.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Industrial Automation</b></span></p>
                    <p className='pt-2'>Manufacturing, logistics, and process automation can all advance as
                        a result of the application of home automation concepts to industrial settings.</p>
                </div>
            </div>
        </div>
    )
}
