import React from 'react'
import './ProjectPages.css'

export default function MazeSolvingRobot() {

    const MazeSolvingRobotcover = [
        {
            src: '/Projects/Projects/DIY Projects.jpg',
            alt: 'MazeSolvingRobot',
        },
    ]

    return (
        <div>
            <div>
                {MazeSolvingRobotcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Maze Solving Robot</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Design and build a robot capable of autonomously navigating through a maze, integrating
                        sensors, algorithms, and decision-making logic to find the optimal path to the exit.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Maze Solving Robot project entails creating a robotic system capable of autonomously
                        navigating through a maze environment. It utilizes sensors, such as ultrasonic or infrared
                        sensors, to detect obstacles and determine the robot&#39;s position within the maze.
                        Participants develop algorithms for path planning and obstacle avoidance, enabling the
                        robot to find the optimal route to the maze&#39;s exit. This project fosters skills in robotics,
                        sensor integration, and algorithm development.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It&#39;s a great teaching tool that provides real-world examples of robotics,
                                computer science, mathematics, and engineering concepts.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Research Advancement</b><br />
                                The development of autonomous navigation is essential to the
                                progress of driverless vehicles, unmanned aerial vehicles, and other autonomous systems.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Skill Development</b><br />
                                Working on a robot that can solve mazes improves knowledge of
                                programming, control systems, robotics, and teamwork. It also helps develop project
                                management skills.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Maze Solving Robot</span> project </p>
                        <p>has a broad future scope and great potential in a number of
                            sectors, including</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Robotics</b></span></p>
                    <p className='pt-2'>It can act as a basis for further investigation into AI, machine
                        learning, and autonomous robots.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Industrial Automation</b></span></p>
                    <p className='pt-2'>This project&#39;s insights may result in advancements for
                        automated guided vehicles (AGVs), which are employed in factories, warehouses,
                        and logistics.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Programmes for Education</b></span></p>
                    <p className='pt-2'>As autonomous robot technology develops, it can
                        improve programmes for education and better prepare students for professions in
                        the robotics, artificial intelligence, and IT sectors.</p>
                </div>
            </div>
        </div>
    )
}
