import React from 'react'
import './schoollogomarquee.css'

export default function SchoollogoMarquee({ images }) {
    return (
        <div className='school_logo_marqueeleft-container'>
            <div className='school_logo_marqueeleft'>
                {images.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} />
                ))}
            </div>
        </div>
    )
}