import React from "react";

const AIandMLprojects = () => {
    const cardData = [
        {
            id: 1,
            title: "AI-Driven Diagnostic Tool",
            text: "Developing an AI tool for medical imaging diagnostics to assist healthcare professionals.",
            imgSrc: "/AI_and_ML/Recent Projects/AI Driven Tool.jpg",
            bgColor: "hsl(346, 100%, 54%)",
        },
        {
            id: 2,
            title: "Speech Recognition Systems",
            text: "Creating advanced speech recognition and synthesis systems for virtual assistants.",
            imgSrc: "/AI_and_ML/Recent Projects/Speech recoganisation System.jpg",
            bgColor: "hsl(260, 100%, 67%)",
        },
        {
            id: 3,
            title: "Predictive Maintenance",
            text: "Designing algorithms to predict maintenance needs for industrial equipment, reducing downtime and costs.",
            imgSrc: "/AI_and_ML/Recent Projects/Predictive Maintenance.jpg",
            bgColor: "hsl(42, 94%, 55%)",
        },
        {
            id: 4,
            title: "Autonomous Systems",
            text: "Developing AI algorithms for self-driving cars and other autonomous vehicles to improve safety and efficiency.",
            imgSrc: "/AI_and_ML/Recent Projects/Autonomus Systems.jpg",
            bgColor: "hsl(24, 100%, 50%)",
        },
    ];

    return (
        <>
            <div className="cards-wrapper d-flex align-items-center justify-content-center flex-wrap">
                {cardData.map((card) => (
                    <div key={card.id} className="card rounded-5 mt-4" style={{ maxWidth: "100%", backgroundColor: card.bgColor }}>
                        <div className="row">
                            <div className="col-12 ">
                                <div className="row">
                                    <div className="col-4">
                                        <img src={card.imgSrc} className="img-fluid rounded-start-5 h-100 w-100" alt="..." />
                                    </div>
                                    <div className="col-8 d-flex align-items-center justify-content-center">
                                        <div className="card-body text-white">
                                            <h5>{card.title}</h5>
                                            <p>{card.text}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default AIandMLprojects;
