import React from 'react'

const Lab = () => {
  return (
    <>  
  <section className='section'>
    <div className="container mt-4">
      <div className="row align-items-center g-5"> 
      <h2 className="fw-bold fs-1 pb-3 text-center"><span className='title'>Why Aeronautics & Aerospace Lab?</span></h2>
         <div className="col-12 col-md-12 col-lg-5">
          <img src="/Aeronautics_and_Aerospace/images/Aerospace-lab.png" className="img-fluid mx-auto d-block" alt=""/>
        </div>
        <div className="col-12 col-md-12 text-center text-lg-start col-lg-7">
          <p className="text col-xl-11 col-md-12">
          Our exclusive lab is equipped with suitable workstations, a reliable power supply, and comprehensive safety and testing equipment, 
          allowing young minds to assemble <span className='fw-bold'>CanSats or CubeSats, and build their own drones, gliders, and RC planes.</span> The availability of necessary 
          resources ensures they can achieve accurate results. The lab is meticulously maintained to ensure children's safety, providing them the  
          freedom to explore, create, and develop. Working in the lab enables focused attention on projects without distractions, fostering a positive  
          learning environment that promotes good learning and happy minds.</p>
        </div>
      </div>
    </div>  
  </section>
    </>
  )
}

export default Lab