import React from 'react'
import './ProjectPages.css'

export default function RCPlane() {

    const RCPlanecover = [
        {
            src: '/Projects/Projects/RC Plane.jpg',
            alt: 'RC Plane',
        },
    ]

    return (
        <div>
            <div>
                {RCPlanecover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>RC Plane</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Build and fly your own remote-controlled aircraft, exploring aerodynamics, electronics, and
                        flight mechanics. Experience the thrill of piloting as you master the art of aviation.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The RC Plane project involves constructing and piloting a remote-controlled aircraft.
                        Participants learn about aerodynamics, structures, electronics, and flight dynamics as they
                        assemble and fly their planes. Using a radio transmitter and receiver, they gain hands-on
                        experience in controlling the aircraft&#39;s throttle, elevator, ailerons, and rudder. This project
                        fosters skills in aviation, craftsmanship, and remote-control systems.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It gives students real-world
                                experience in physics, math, and
                                engineering and is a great teaching tool. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Engagement of Hobbyists</b><br />
                                Building and piloting remote-controlled
                                aircraft is an enjoyable pastime that fosters
                                technical proficiency and inventiveness. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Skill Development</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>RC Plane</span> project </p>
                        <p>offers a wide range of potential uses in numerous industries in the future like,</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research:</b></span></p>
                    <p className='pt-2'>It can be the foundation for state-of-the-art investigations into
                        aerospace engineering and unmanned aerial vehicles (UAVs).</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Innovation in UAVs:</b></span></p>
                    <p className='pt-2'>Information from RC plane projects can be used to develop
                        more sophisticated UAVs for delivery, surveillance, and agricultural tracking.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Advancement in Education</b></span></p>
                    <p className='pt-2'>As RC plane projects continue to grow, more
                        comprehensive curriculum that educate students for careers in aerospace related industries might be developed.</p>
                </div>
            </div>
        </div>
    )
}
