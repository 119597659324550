import React from 'react'
import './AeronauticsandAerospace.css'
import Home from './aeronauticsandaerospace/components/Home'
import Lab from './aeronauticsandaerospace/components/Lab'
import Cards from './aeronauticsandaerospace/components/Cards'
import Program from './aeronauticsandaerospace/components/Program'
import Gallery from './aeronauticsandaerospace/components/Gallery'
import FAQs from './aeronauticsandaerospace/components/FAQs'

export default function AeronauticsandAerospace() {

  const aeronauticsandaerospacecover = [

    {
      src: '/Aeronautics_and_Aerospace/Aeronautics and Aerospace Lab.jpg',
      alt: 'aeronauticsandaerospacecover',
    }

  ]

  return (
    <div>
      <div>
        {aeronauticsandaerospacecover.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
        ))}
      </div>
      {/* <Home /> */}
      <Lab />
      <Cards />
      <Program />
      <Gallery />
      <FAQs />
    </div>
  )
}
