import React from 'react'
import './ProjectPages.css'

export default function Cansat() {

    const Cansatcover = [
        {
            src: '/Projects/Projects/Cansat.jpg',
            alt: 'Cansat',
        },
    ]

    return (
        <div>
            <div>
                {Cansatcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Cansat</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Design and launch a CanSat, exploring satellite technology and data collection through a
                        miniature satellite payload.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The CanSat project revolves around designing and launching a miniature satellite
                        payload enclosed in a soda can-sized container. It explores satellite technology and data
                        collection methods. CanSats typically incorporate sensors, communication systems, and
                        data logging capabilities. The primary goal is to gather and transmit data from various
                        environmental parameters during descent and flight, providing valuable insights into
                        atmospheric conditions.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It&#39;s a great teaching tool that provides real-world examples of
                                physics, arithmetic, computer science, and engineering concepts.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Engagement of Hobbyists</b><br />
                                Constructing and testing CanSats is a fulfilling hobby that
                                fosters creativity and improves technical proficiency. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Development of Skills</b><br />
                                Working on CanSats fosters cooperation and project
                                management skills while enhancing knowledge of electronics, programming, and
                                aerospace systems. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>CanSat</span> project </p>
                        <p>project has a broad future scope and great potential in a number of fields</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research</b></span></p>
                    <p className='pt-2'>It can provide as a starting point for more in-depth studies in
                        environmental monitoring, space exploration, and satellite technology.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Commercial Applications</b></span></p>
                    <p className='pt-2'>CanSat projects&#39; insights may spur new developments in
                        the fields of remote sensing, telecommunications, and weather forecasting.
                        Constant improvements in CanSat technology have the potential to improve
                        educational programmes and better prepare students for professions in the IT,
                        electronics, and aerospace industries.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Applications in the Environment and Science</b></span></p>
                    <p className='pt-2'>CanSats can be modified for vital uses
                        in environmental monitoring, science, and emergency preparedness. This increases
                        operating effectiveness and yields useful data.</p>
                </div>
            </div>
        </div>
    )
}
