import React from 'react'

const Lab = () => {
  return (
    <>  
  <section className='section'>
    <div className="container mt-4">
      <div className="row align-items-center g-5"> 
      <h2 className="fw-bold fs-1 pb-3 text-center"><span className='title'>Why AI & ML Lab?</span></h2>
         <div className="col-12 col-md-12 col-lg-5">
          <img src="/AI_and_ML/Lab/AI & ML lab.png" className="img-fluid mx-auto d-block" alt=""/>
        </div>
        <div className="col-12 col-md-12 text-center text-lg-start col-lg-7">
          <p className="text col-xl-11 col-md-12">
          Our AI & ML Innovation Center offers a unique hands-on experience in artificial intelligence and machine learning. Equipped with high-performance tools and guided by experts, students can develop and test cutting-edge AI models and applications, transforming their innovative ideas into reality.</p>
        </div>
      </div>
    </div>  
  </section>
    </>
  )
}

export default Lab