import React, { useState } from 'react' 
import { IoMdArrowDropdown,IoMdArrowDropup } from "react-icons/io";

const FAQs = () => {
    const[selected,setselected] = useState(null); 
     
    const toggle = (i) =>{ 
        if(selected == i){ 
            return setselected(null);
        } 
        setselected(i);
        
    }

    const data = [
        { question: '1. What types of projects will students work on in the lab?', 
          answer: 'Students will engage in projects such as designing, constructing, and testing drones, RC planes, balsawood gliders, and CANSATs. They will also learn 3D design using software like Fusion 360.' }, 

        { question: '2. Which grade levels is the lab designed for?',  
          answer: 'The lab is designed for students typically from grades 1 to 12.' }, 

        { question: '3. Do students need prior experience to participate?', 
          answer: 'No prior experience is required. Our programs are designed to cater to beginners as well as those with some background knowledge.' }, 

        { question: '4. What materials and tools will be provided?',  
          answer: 'We provide all necessary materials and tools, including drone kits, RC plane components, balsa wood, CANSAT parts, and access to 3D design software and 3D printers. All necessary materials and tools are provided as part of the project. Students do not need to purchase additional supplies. If required, they can buy our customized kits from our product page.' }, 

        { question: '5. How long is a typical Aeronautics and Aerospace workshop? ', 
           answer: 'Workshops typically range from one-day intensive sessions to multi-week courses, depending on the depth and complexity of the topic.' },  
         
        { question: '6. Who are the instructors for these workshops? ', 
           answer: ' Our instructors are experienced professionals and educators with expertise in aeronautics and aerospace engineering.' }, 
            
        { question: '7. Are there any safety guidelines students must follow?', 
           answer: 'Yes, safety is a top priority. Students must adhere to all safety guidelines provided by instructors, including wearing safety gear and following proper procedures when using tools and equipment.' }, 
            
        { question: '8. Can workshops be customized for specific groups or schools? ', 
           answer: 'Yes, we offer customized workshops tailored to the specific needs and interests of different groups and schools.' }, 
            
        { question: '9. How can I find the schedule for upcoming workshops? ', 
           answer: ' The schedule for upcoming workshops is available on our website. You can also sign up for our newsletter to receive updates' }, 
            
        { question: '10. What is the cost of participating in an Aeronautics and Aerospace workshop? ', 
          answer: 'The cost varies depending on the duration and content of the workshop. Detailed pricing is available on our website.' },

    ];

    return (
        <section className='section'>
            <div className="container"> 
               <h2 className='title text-center mb-5'>Frequently Asked Questions (FAQs)</h2> 
             <div className='mx-lg-5'>
                {data.map((item, i) => (
                    <div className='mb-2 rounded-2 px-4 py-2 mx-lg-5 border border-2 cursor-pointer' style={{backgroundColor:'#fff' ,color: '#0a58ca'}} > 
                        <div className='d-flex justify-content-between align-items-center p-1 ' onClick={()=>toggle(i)}>
                            <h3 className='h5'>{item.question}</h3> 
                            <span className='fs-4'>{selected === i ? <IoMdArrowDropup/> : <IoMdArrowDropdown />}</span>
                        </div>
                        <div className={selected === i ? 'content show' : 'content'}>
                            <p className='text px-2'>{item.answer}</p>
                        </div>
                    </div>
                ))}
             </div> 
          </div>
        </section>
    )
}

export default FAQs;
