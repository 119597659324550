// import React from 'react';
// import Carousel from 'react-bootstrap/Carousel';

// function AIandMLcards() {

//     const SchoolProgramscover = [

//         {
//             src: '/AI_and_ML/Cards/AI and ML 1.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 2.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 3.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 4.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 5.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 6.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 7.jpg',
//             alt: 'AI and ML',
//         },
//         {
//             src: '/AI_and_ML/Cards/AI and ML 8.jpg',
//             alt: 'AI and ML',
//         },

//     ];

//     return (
//         <Carousel>
//             {SchoolProgramscover.map((image, index) => (
//                 <Carousel.Item key={index} interval={3000}>
//                     <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                         <img src={image.src} alt={image.alt} className='img-fluid' style={{ marginRight: '20px' }} />
//                     </div>
//                 </Carousel.Item>
//             ))}
//         </Carousel>
//     );
// }

// export default AIandMLcards;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function AIandMLcards() {

    const SchoolProgramscover = [
        { src: '/AI_and_ML/Cards/AI and ML 1.jpg', alt: 'AI and ML 1' },
        { src: '/AI_and_ML/Cards/AI and ML 2.jpg', alt: 'AI and ML 2' },
        { src: '/AI_and_ML/Cards/AI and ML 3.jpg', alt: 'AI and ML 3' },
        { src: '/AI_and_ML/Cards/AI and ML 4.jpg', alt: 'AI and ML 4' },
        { src: '/AI_and_ML/Cards/AI and ML 5.jpg', alt: 'AI and ML 5' },
        { src: '/AI_and_ML/Cards/AI and ML 6.jpg', alt: 'AI and ML 6' },
        { src: '/AI_and_ML/Cards/AI and ML 7.jpg', alt: 'AI and ML 7' },
        { src: '/AI_and_ML/Cards/AI and ML 8.jpg', alt: 'AI and ML 8' },
    ];

    // Group images into sets of 4
    const groupedImages = [];
    for (let i = 0; i < SchoolProgramscover.length; i += 4) {
        groupedImages.push(SchoolProgramscover.slice(i, i + 4));
    }

    return (
        <Carousel>
            {groupedImages.map((group, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {group.map((image, idx) => (
                            <img key={idx} src={image.src} alt={image.alt} className='img-fluid rounded-5' style={{ width: '24%', marginRight: '1%' }} />
                        ))}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default AIandMLcards;
