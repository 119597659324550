import React from 'react'

const Lab = () => {
  return (
    <>  
  <section className='section'>
    <div className="container mt-4">
      <div className="row align-items-center g-5"> 
      <h2 className="fw-bold fs-1 pb-3 text-center"><span className='title'>Why STEM & Robotics Lab?</span></h2>
         <div className="col-12 col-md-12 col-lg-5">
          <img src="/STEM & Robotics/Lab/Robotics Lab Finalized.png" className="img-fluid mx-auto d-block" alt=""/>
        </div>
        <div className="col-12 col-md-12 text-center text-lg-start col-lg-7">
          <p className="text col-xl-11 col-md-12">
          Our STEM & Robotics Lab is a cutting-edge innovation center, equipped with advanced workstations and testing robots. Students here can explore robotics through hands-on projects, including creating prototypes, humanoid robots, WiFi-enabled cars, and interactive robots. The lab also offers 3D printing and DIY electronics projects, all within a framework of rigorous safety protocols. Meticulously maintained, it provides a safe and inspiring environment for students to turn their ideas into reality. At the MH Intellect Lab, students experience transformative learning and innovation firsthand.</p>
        </div>
      </div>
    </div>  
  </section>
    </>
  )
}

export default Lab