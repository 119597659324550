import React from 'react';
import aboutImage from '../../images/7.jpg';
import './InnovatorsTwo.css';

const InnovatorsTwo = () => {
  return (
    <div className="innovators-container">
      <div className="innovators-content">
        <div className="image-container">
          <div className="image-wrapper">
            <img
              src={aboutImage}
              alt="About STEM Education"
              className="image"
            />
          </div>
        </div>
        <div className="text-container">
          <h1 className="title">Unlock Your Potential with Our STEM and Robotics</h1>
          <p className="description">Where imagination meets innovation</p>
          <p className="description">
           Dive into hands-on projects and expert-led sessions, exploring the realms of science, technology, engineering, and math. Gain practical skills and critical insights to fuel your passion for discovery. Join us in shaping the future through creative problem-solving and cutting-edge exploration. With our courses, the possibilities are limitless, and your journey towards excellence begins here
          </p>
          <div className="extra-element">
            {/* Add any other element here instead of a button */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovatorsTwo;
