import React from "react";

const Program = () => {
  const cardData = [
    {
      id: 1,
      title: "Glider",
      text: "Understand the basics of aerodynamics and build your own glider.",
      imgSrc: "/Aeronautics_and_Aerospace/images/Glider.jpg",
      bgColor: "hsl(346, 100%, 54%)",
    },
    {
      id: 2,
      title: "Drone",
      text: "Explore the technology behind drones and learn how to pilot them.",
      imgSrc: "/Aeronautics_and_Aerospace/images/Drones.jpg",
      bgColor: "hsl(260, 100%, 67%)",
    },
    {
      id: 3,
      title: "RC Plane",
      text: "Get practical learning experience in building and flying remote-controlled planes.",
      imgSrc: "/Aeronautics_and_Aerospace/images/RC Plane.jpg",
      bgColor: "hsl(42, 94%, 55%)",
    },
    {
      id: 4,
      title: "CanSat",
      text: "Design and launch a miniature satellite.",
      imgSrc: "/Aeronautics_and_Aerospace/images/CANSAT.jpg",
      bgColor: "hsl(24, 100%, 50%)",
    },
    {
      id: 5,
      title: "CAD",
      text: "Learn to create detailed designs using CAD software. ",
      imgSrc: "/Aeronautics_and_Aerospace/images/CAD.jpg",
      bgColor: "#191c47",
    },
    {
      id: 6,
      title: "Flapping UAV",
      text: "Discover the mechanics behind flapping wing unmanned aerial vehicles.",
      imgSrc: "/Aeronautics_and_Aerospace/images/Flaping Wing UAV.jpg",
      bgColor: "hsl(170, 75%, 41%)",
    },
  ];

  return (
    <>
    <section className="section">  
      <div className="container">  
        <div className='row align-items-center justify-content-center'>
          <div className="col-12 col-md-8 col-lg-8">
            <img src="/Aeronautics_and_Aerospace/images/misl.png" className="img-fluid" alt="misl"/> 
         </div>   
        <div className="col-12 col-md-4 col-lg-4 hero-btns text-center text-md-start text-lg-start pt-md-5 mt-md-5 pt-lg-5 mt-lg-5 mt-5">
           <h3 className="text-dark my-3">Empowering <br/> <span className='text-success'>Tomorrow's</span> <br/> Aerospace Pioneers !!</h3>
           <a className="btn hero-btn px-3 py-2 fs-7 fw-medium rounded-5 has-before bg-success text-white" href="#">Enroll Now</a> 
        </div>
        <div className='col-12 '>
          <p className='text text-justify mt-5 px-lg-5'>Our Kids STEM Program introduces young learners to the exciting world of Aeronautics and Aerospace. Through engaging, hands-on activities,  
          children explore the principles of flight, Rocket science, and Space exploration. Using age-appropriate technologies and interactive simulations, 
          the program fosters creativity, Critical thinking, and Problem-solving skills. <span className="fw-bold"> Kids build and test their own Aircraft and Rockets, gaining a foundational 
          understanding of Aerospace engineering concepts.</span> This fun and educational experience inspires a lifelong interest in Science and Technology, preparing the  
          next generation of innovators & explorers.</p>
        </div>  
      </div> 
     </div>
        <div className="container mt-5">
          <div className="cards-wrapper d-flex align-items-center justify-content-center flex-wrap">
            {cardData.map((card) => (
              <div key={card.id} className="card rounded-5 mt-4" style={{ maxWidth: "700px", backgroundColor: card.bgColor }}>
                <div className="row">
                  <div className="col-12 "> 
                   <div className="row">
                    <div className="col-4">
                      <img src={card.imgSrc} className="img-fluid rounded-start-5 h-100 w-100" alt="..."/>
                    </div>
                    <div className="col-8 d-flex align-items-center justify-content-center">
                      <div className="card-body text-white">
                        <h5>{card.title}</h5>
                        <p>{card.text}</p>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="mt-4">
        <img src="/Aeronautics_and_Aerospace/images/STUD.png" className="img-fluid" alt="" />
      </div>
    </>
  );
};

export default Program;
