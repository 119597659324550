import React from 'react'
import './ProjectPages.css'

export default function PickandPlaceRoboticArm() {

    const PickandPlaceRoboticArmcover = [
        {
            src: '/Projects/Projects/Robotics 2.jpg',
            alt: 'PickandPlaceRoboticArm',
        },
    ]

    return (
        <div>
            <div>
                {PickandPlaceRoboticArmcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Pick and Place Robotic Arm</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Build a robotic arm capable of picking and placing objects with precision, learning about
                        robotics and automation.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Pick and Place Robotic Arm project aims to create a robotic arm capable of
                        manipulating objects with precision. It involves mechanical design, actuator selection,
                        and programming of control algorithms. Utilizing sensors such as proximity sensors and
                        encoders, the arm detects and grasps objects in its environment. Applications may
                        include industrial automation, assembly lines, and warehouse logistics.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It&#39;s a great teaching tool that provides real-world examples of
                                physics, arithmetic, computer science, and engineering concepts.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Industrial Applications</b><br />
                                To increase production and efficiency through automation in
                                manufacturing, logistics, and other industries, it is essential to comprehend robotic
                                arms. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Development of Skills</b><br />
                                Working on robotic arms fosters cooperation and project
                                management skills while enhancing knowledge of robotics, electronics, and
                                programming. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Pick and Place Robotic Arm</span> project </p>
                        <p>has a wide future scope and great potential in many
                            different fields.</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research</b></span></p>
                    <p className='pt-2'>It can act as a basis for more in-depth studies in machine
                        learning, artificial intelligence, and robotics.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Industrial Automation</b></span></p>
                    <p className='pt-2'>This project&#39;s insights may inspire new developments in this
                        field, enhancing distribution, storage, and manufacturing procedures.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Academic Programmes</b></span></p>
                    <p className='pt-2'>Ongoing developments in robotic arm technology have the
                        potential to improve academic programmes and better prepare students for careers
                        in the IT, engineering, and robotics sectors.</p>
                </div>
            </div>
        </div>
    )
}
