import React from "react";
import './App.css'
import 'bootstrap/dist/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Home";
import OurProducts from "./OurProducts";
import ScrollToTop from "./ScrollToTop";
import Projects from "./Projects";

import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from './Logo/MH Intellect LOGO.png'; // Assuming you have your logo image
import { FaDesktop } from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IoIosContact } from "react-icons/io";
import StemandRobotics from "./StemandRobotics";
import Footer from "./Footer";
import AeronauticsandAerospace from "./AeronauticsandAerospace";
import TwoLeggedWalkingRobot from "./Project Pages/TwoLeggedWalkingRobot";
import RCPlane from "./Project Pages/RCplane";
import Glider from "./Project Pages/Glider";
import QuadcopterDrone from "./Project Pages/QuadcopterDrone";
import Cansat from "./Project Pages/Cansat";
import PickandPlaceRoboticArm from "./Project Pages/PickandPlaceRoboticArm";
import IoTbasedHomeAutomation from "./Project Pages/IoTbasedHomeAutomation";
import Artificialintelligenceproject from "./Project Pages/Artificialintelligenceproject";
import MachineLearningproject from "./Project Pages/MachineLearningproject";
import DataAnalyticsProject from "./Project Pages/DataAnalyticsProject";
import Labs from "./Labs";
import SchoolPrograms from "./SchoolPrograms/SchoolPrograms";
import WritingandDrawingBot from "./Project Pages/WritingandDrawingBot";
import MazeSolvingRobot from "./Project Pages/MazeSolvingRobot";
import OurGovernance from "./OurGovernance";
import Login from "./Login/Login";
import Register from "./Register/Register";
import Gallery from "./Gallery";
import AIandML from "./AI and ML/AIandML";
import OurProducts_1 from "./OurProducts_1";

const scrollToContact = (event) => {
  event.preventDefault();
  document.querySelector('#contact-section').scrollIntoView({ behavior: 'smooth' });
};

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
        <Router>
          <ScrollToTop />

          <Navbar expand="lg" bg="black" variant="light" sticky="top" className="custom-navbar">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src={logo}
                  width="150"
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" className="custom-toggle" />
              <Navbar.Collapse id="responsive-navbar-nav" className="custom-collapse">
                <Nav className="mx-auto nav-items mr-auto px-3 gap-lg-3 gap-md-2 gap-sm-1">
                  <Nav.Link href="/school-programs" className="text-white">School Programs</Nav.Link>
                  <Nav.Link href="/lab" className="text-white">Lab</Nav.Link>
                  <Nav.Link href="/projects" className="text-white">Projects</Nav.Link>
                  <Nav.Link href="/products" className="text-white">Products</Nav.Link>
                  <Nav.Link href="/" className="text-white">Shop</Nav.Link>
                  <Nav.Link href="/our-governance" className="text-white">Our Governance</Nav.Link>
                  <Nav.Link href="/gallery" className="text-white">Gallery</Nav.Link>
                </Nav>
                <Nav className="nav-icons">
                  <Nav.Link href="/#request-demo-form" className="align-items-center text-white navbar_icon">
                    <FaDesktop color="orange" size="20px" /><br />
                    <span className="icon-label ms-1">Book a Demo</span>
                  </Nav.Link>
                  <Nav.Link href="/#contact" className="align-items-center text-white navbar_icon" onClick={scrollToContact}>
                    <FiPhone color="lightblue" size="20px" /><br />
                    <span className="icon-label ms-1">Contact</span>
                  </Nav.Link>
                  {/* <Nav.Link href="/cart" className="align-items-center text-white navbar_icon">
                    <AiOutlineShoppingCart color="blue" size="30px" /><br />
                    <span className="icon-label ms-1">Cart</span>
                  </Nav.Link>
                  <Nav.Link href="/profile" className="align-items-center text-white navbar_icon">
                    <IoIosContact color="lightblue" size="30px" /><br />
                    <span className="icon-label ms-1">Profile</span>
                  </Nav.Link> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>




          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/products" element={<OurProducts_1 />} />
            <Route exact path="/school-programs" element={<SchoolPrograms />} />
            <Route exact path="/lab" element={<Labs />} />
            <Route exact path="/projects" element={<Projects />} />
            <Route exact path="/stem-and-robotics" element={<StemandRobotics />} />
            <Route exact path="/aeronautics-and-aerospace" element={<AeronauticsandAerospace />} />
            <Route exact path="/rc-plane" element={<RCPlane />} />
            <Route exact path="/glider" element={<Glider />} />
            <Route exact path="/drone" element={<QuadcopterDrone />} />
            <Route exact path="/cansat" element={<Cansat />} />
            <Route exact path="/advanced-robotics" element={<TwoLeggedWalkingRobot />} />
            <Route exact path="/robotics" element={<PickandPlaceRoboticArm />} />
            <Route exact path="/IOT" element={<IoTbasedHomeAutomation />} />
            <Route exact path="/artificial-intelligence" element={<Artificialintelligenceproject />} />
            <Route exact path="/machine-learning" element={<MachineLearningproject />} />
            <Route exact path="/data-analytics" element={<DataAnalyticsProject />} />
            <Route exact path="/coding" element={<WritingandDrawingBot />} />
            <Route exact path="/diy-projects" element={<MazeSolvingRobot />} />
            <Route exact path="/our-governance" element={<OurGovernance />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/ai-and-ml" element={<AIandML />} />
            {/* <Route exact path="/profile" element={<Login />} />
            <Route exact path="/register" element={<Register />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </div>
      <div id="contact-section"></div>
      <Footer />
    </div>
  );
}

export default App;
