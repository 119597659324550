import React from 'react'
import './marqueeright.css'

export default function Marqueeright({ images }) {
    return (
        // <div className='marqueeright-container'>
        //     <div className='marqueeright'>
        //         {images.map((image, index) => (
        //             <img key={index} src={image.src} alt={image.alt} />
        //         ))}
        //     </div>
        // </div>

        <div className="marquee_right_container">
            <div className="marquee_right_content">
                {images.map((image, index) => (
                    <a key={index} href={image.link} rel="noopener noreferrer">
                        <img src={image.src} alt={image.alt} />
                    </a>
                ))}
                {/* Duplicate the images to create a seamless scroll effect */}
                {images.map((image, index) => (
                    <a key={index + images.length} href={image.link} rel="noopener noreferrer">
                        <img src={image.src} alt={image.alt} />
                    </a>
                ))}
            </div>
        </div>
    )
}
