import React from 'react' 

const Home = () => {
  return (
   <> 
    <section className="hero-section section d-flex align-items-top justify-content-center align-items-sm-center align-items-md-center align-items-lg-center">
        <div className="container py-5 my-5">
          <div className="row align-items-center g-5">
            <div className="col-12 col-sm-6 col-lg-5">
              <div className="text-center text-sm-start text-md-start">
                <h1 className="display-4 fw-bold pb-2"><span className="text-primary hero-title">Aeronautics & Aerospace Lab</span></h1>
                <p className="text-justify fs-7">Advancing flight technologies through research, innovation, and experimentation in aeronautics and aerospace engineering</p> 
                <div className='hero-btns'>
                  <a className="btn hero-btn px-3 py-2 mt-3 me-3 fs-7 fw-medium rounded-5 has-before">Watch Video</a> 
                  <a href="/#request-demo-form" className="btn hero-btn px-3 py-2 mt-3 fs-7 fw-medium rounded-5 has-before">Book Demo</a> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
   </>
  )
}

export default Home