import React from 'react'
import './Aiandroboheader.css';

const Aiandroboheader = () => {
  return (
    // <div>
    //      <header className="aiandrobo-header">
      
    //   <div className="overlay">
       
    //     <div className="aicontent">
    //       <h1 className="header-title">STEM & Robotics Lab</h1>
    //       <p className="header-description">The STEM & Robotics Lab offers hands-on learning in Science, Technology, <br></br>Engineering, and Math through creative Robotics projects, enhancing<br></br>problem-solving and critical thinking skills.</p>
    //     </div>
        
    //   </div>
    //   <div className='aiandrobohbtn'>
    //         <button>Watch a video</button>
    //         <button><a href="/#request-demo-form" className='text-white text-decoration-none'>Book a Demo</a></button>
    //     </div>
        
    // </header>
    // </div>
    <section className="hero-section section d-flex align-items-top justify-content-center align-items-sm-center align-items-md-center align-items-lg-" id='aiandrobo-header'>
    <div className="container py-5 my-5">
      <div className="row align-items-center g-5">
        <div className="col-12 col-sm-6 col-lg-5">
          <div className="text-center text-sm-start text-md-start">
            <h1 className="display-4 fw-bold pb-2"><span className="text-primary hero-title">STEM & Robotics Lab</span></h1>
            <p className="text-justify fs-7">The STEM & Robotics Lab offers hands-on learning in Science, Technology, Engineering, and Math through creative Robotics projects, enhancing problem-solving and critical thinking skills.</p> 
            <div className='hero-btns'>
              <a className="btn hero-btn px-3 py-2 mt-3 me-3 fs-7 fw-medium rounded-5 has-before">Watch Video</a> 
              <a href="/#request-demo-form" className="btn hero-btn px-3 py-2 mt-3 fs-7 fw-medium rounded-5 has-before">Book Demo</a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
  )
}

export default Aiandroboheader
