import React from 'react'
import './AIandML.css'
import AIandMLcards from './AIandMLcards'
import { Container, Row, Col } from 'react-bootstrap';
import AIandMLprojects from './AIandMLprojects';
import AIandMLfaq from './AIandMLfaq';
import Lab from './Lab';

export default function AIandML() {

    const aiandmlcover = [
        {
            src: '/AI_and_ML/AI&ML LABBBBB.jpg',
            alt: 'AI and ML',
        },
    ]

    const icon1 = [
        {
            src: '/AI_and_ML/Icons/AI & ML Icon 1.png',
            alt: 'icon',
            text: 'High-Performance Computing Clusters',
        },
        {
            src: '/AI_and_ML/Icons/AI & ML Icon 2.png',
            alt: 'icon',
            text: 'Deep Learning Frameworks',
        },
    ]

    const icon2 = [
        {
            src: '/AI_and_ML/Icons/AI & ML Icon 3.png',
            alt: 'icon',
            text: 'Data Analytics and Visualization Tools',
        },
        {
            src: '/AI_and_ML/Icons/AI & ML Icon 4.png',
            alt: 'icon',
            text: 'AI Research Platforms',
        },
    ]

    return (
        <div>
            <div>
                {aiandmlcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                ))}
            </div>
            <Lab />
            <div className='container py-5 text-center AI_and_ML_intro'>
                <p>Our AI &amp; ML program introduces students to the transformative world of artificial intelligence and
                    machine learning. Through a blend of theoretical knowledge and practical applications, students will
                    gain insights into this rapidly evolving field.</p>
            </div>
            <div className='container text-center py-5'>
                <h1>Program Highlights</h1>
            </div>
            <div className='container py-5'>
                <AIandMLcards />
            </div>
            <div className='AI_and_ML_blue'>
                <div className='container py-5'>
                    <div className='text-center'>
                        <h1>Facilities and Equipment</h1>
                    </div>
                    <Container>
                        <Row className="text-center">
                            {icon1.map((image, index) => (
                                <Col key={index} xs={12} md={6} className="mb-4">
                                    <div className="AI_and_ML_icons align-items-center">
                                        <img src={image.src} alt={image.alt} className="img-fluid w-25" />
                                        <p>{image.text}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                        <Row className="text-center">
                            {icon2.map((image, index) => (
                                <Col key={index} xs={12} md={6} className="mb-4">
                                    <div className="AI_and_ML_icons align-items-center">
                                        <img src={image.src} alt={image.alt} className="img-fluid w-25" />
                                        <p>{image.text}</p>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </div>
            {/* <div className='text-center py-5'>
                <h1>Facilities and Equipment</h1>
            </div> */}
            <div className='container py-5'>
                <AIandMLprojects />
                <AIandMLfaq />
            </div>
        </div>
    )
}
