import React from 'react';
import './ImageGallery.css';
import img1 from '../../images/22.jpg';
import img2 from '../../images/10.jpg';
import img3 from '../../images/5.jpg';

const ImageGallery = () => {
  const images = [
    {
      src: img1,
      title: 'Advance robotics lab',
      description: 'Equipped with state-of-the-art infrastructure and project-based learning kits for hands-on exploration',
    },
    {
      src: img2,
      title: 'Programming',
      description: 'Explore Programming in embedded systems, Python development, and AI-focused coding, and exploring computer vision',
    },
    {
      src: img3,
      title: 'Project centered curriculum',
      description: 'Year-long curriculum integrating NEP 2020 and IB/CBSE skill subjects for student',
    },
  ];

  return (
    <section className="gallery-section">
      <h1 className="gallery-heading">Rise above traditional robotics labs and innovate with our Intellect Program!</h1>
      <h2 className="gallery-subheading">Begin an engaging educational voyage, mastering cutting-edge robotics in our state-of-the-art facility to tackle practical problems and forge the path to innovation</h2>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className="image-card">
            <img src={image.src} alt={image.title} className="image_card"/>
            <div className="image-title">{image.title}</div>
            <div className="image-description">{image.description}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImageGallery;