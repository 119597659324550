import React from 'react'
import Aiandroboheader from './stemandrobotics/Component/AiandRoboHeader/Aiandroboheader'
import Aiandrobo from './stemandrobotics/Component/AI and Robotics/Aiandrobo'
import ImageGallery from './stemandrobotics/Component/ImageGallery/ImageGallery'
import InnovatorsTwo from './stemandrobotics/Component/InnovatorsTwo/InnovatorsTwo'
import PhotoGallery from './stemandrobotics/Component/PhotoGallery/PhotoGallery'
import StemandRoboticsFAQ from './stemandrobotics/Component/FAQ/StemandRoboticsFAQ'
import Lab from './stemandrobotics/Component/lab/Lab'

export default function StemandRobotics() {

  const stemandroboticscover = [

    {
      src: '/STEM & Robotics/STEM & Robotics.jpg',
      alt: 'stemandroboticscover',
    }

  ]

  return (
    <div>
      <div>
        {stemandroboticscover.map((image, index) => (
          <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
        ))}
      </div>
      {/* <Aiandroboheader /> */}
      <Lab />
      <Aiandrobo />
      <ImageGallery />
      <InnovatorsTwo />
      <PhotoGallery />
      <StemandRoboticsFAQ />
    </div>
  )
}
