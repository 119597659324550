import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const Gallerys = Array.from({ length: 8 }, (_, index) => ({ imgSrc: `/Aeronautics_and_Aerospace/gallery/IMG_${index + 1584945281}.JPG` }));

const Gallery = () => { 
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '0px 0px -100px 0px', once: true });

  return (
    <> 
      <section className='section'>
        <div className="container mt-4">
          <div className="text-center">
            <h1 className="fw-bold fs-1 mb-5 title text-center">Premier Aeronautics and Aerospace Education for Kids</h1> 
            <h6 className="text-center px-3 mb-5 text">We have established numerous Aeronautics and Aerospace Labs! Our commitment to advancing aviation technology is stronger than ever, and we look forward to continually pushing the boundaries of what is possible.</h6>
          </div>
          <div className="row g-4" ref={ref}>
            {Gallerys.map((Gallery, index) => (
              <motion.div
                className="col-6 col-md-4 col-lg-3"
                initial={{ opacity: 0, y: 50 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ delay: isInView ? index * 0.2 : 0, duration: 0.6, ease: 'easeOut' }}
                key={index}>   
                <div className="text-center">
                  <img src={Gallery.imgSrc} alt={Gallery.title} className="img-fluid rounded-3" />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Gallery; 
