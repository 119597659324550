import React, { useState } from 'react';
import './PhotoGallery.css';
import photo1 from '../../images/gesture.jpg';
import photo2 from '../../images/Line-follwer (8).jpg';
import photo3 from '../../images/obrobo.jpg';
import photo4 from '../../images/pet feed.jpg';
import photo5 from '../../images/weather.jpg';
import photo6 from '../../images/trafic light.jpg';
import photo7 from '../../images/plant water.jpg';
import photo8 from '../../images/motion.jpg';

const PhotoGallery = () => {
    const [animationActive, setAnimationActive] = useState(false);

    const startAnimation = () => {
        setAnimationActive(true);
    };

    const stopAnimation = () => {
        setAnimationActive(false);
    };

    const photos = [photo1, photo2, photo3, photo4, photo5, photo6, photo7, photo8];
    const titles = [
        'Gesture control car',
        'Line following car',
        'Obstacle avoidance car',
        'Automatic pet feeder',
        'Iot weather station',
        'Traffic light controller',
        'Plant watering system',
        'Arduino motion detector'
    ];

    return (
        <div className="photo-gallery-section">
            <div className="photo-gallery-container">
                <h2 className="photo-gallery-heading">Technologies in Focus for Inclusive STEM and Robotics Education</h2>
                <p className="photo-gallery-subheading"></p>
                <div className="photo-container">
                    {photos.map((photo, index) => (
                        <div
                            key={index}
                            className={`photo-card ${animationActive ? 'animate' : ''}`}
                            onMouseEnter={startAnimation}
                            onMouseLeave={stopAnimation}
                        >
                            <img src={photo} alt={`Photo ${index + 1}`} className="photo" />
                            <div className="photo-title">{titles[index]}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PhotoGallery;
