// import React from 'react'
// import './projects.css'
// import { Container, Row, Col } from 'react-bootstrap';

// const ImageColumn = ({ src, alt }) => (
//     <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
//         <center>
//             <div className="p-3 project_img_hover">
//                 <img src={src} alt={alt} className='img-fluid our_products_cover project_img' />
//             </div>
//         </center>
//     </Col>
// );

// export default function Gallery() {

//     const projectsimg = [

//         {
//             src: '/Gallery/1.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/2.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/3.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/4.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/5.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/6.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/7.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/8.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/9.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/10.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/11.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/12.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/13.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/14.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/15.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/16.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/17.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/18.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/19.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/20.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/21.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/22.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/23.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/24.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/25.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/26.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/27.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/28.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/29.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/30.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/31.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/32.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/33.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/34.jpg',
//             alt: 'Gallery',
//         },
//         {
//             src: '/Gallery/35.jpg',
//             alt: 'Gallery',
//         },

//     ]

//     return (
//         <div>
//             <div className='py-5 text-center'>
//                 <h1>Gallery.</h1>
//             </div>
//             <div className='pt-5'>
//                 <Container>
//                     <Row>
//                         {projectsimg.map((image, index) => (
//                             <ImageColumn
//                                 key={index}
//                                 src={image.src}
//                                 alt={image.alt}
//                             />
//                         ))}
//                     </Row>
//                 </Container>
//             </div>
//         </div>
//     )
// }


import React from 'react';
import './projects.css';
import { Container, Row, Col } from 'react-bootstrap';

const images = Array.from({ length: 68 }, (_, i) => ({
  src: `/Gallery/${i + 1}.jpg`,
  alt: 'Gallery',
}));

const ImageColumn = ({ src, alt }) => (
  <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
    <center>
      <div className="p-3 project_img_hover">
        <img src={src} alt={alt} className="img-fluid our_products_cover project_img" />
      </div>
    </center>
  </Col>
);

export default function Gallery() {
  return (
    <div>
      <div className="py-5 text-center">
        <h1>Gallery</h1>
      </div>
      <div className="pt-5">
        <Container>
          <Row>
            {images.map((image, index) => (
              <ImageColumn key={index} src={image.src} alt={image.alt} />
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
}
