import React from 'react'
import './labs.css'
import { Link } from 'react-router-dom'

export default function Labs() {

    const labcover = [
        {
            src: '/Lab_page/LAB Cover.jpg',
            alt: 'ourproductscover',
        },
    ]

    const stemrobotics = [
        {
            src: '/Lab_page/lbbbb_1.png',
            alt: 'ourproductscover',
        },
    ]

    const aeronauticsaerospace = [
        {
            src: '/Lab_page/LAAAAAB_2.jpg',
            alt: 'ourproductscover',
        },
    ]

    const aiml = [
        {
            src: '/Lab_page/LAB 03.png',
            alt: 'ourproductscover',
        },
    ]

    return (
        <div>
            <div>
                {labcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid our_products_cover' />
                ))}
            </div>
            <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                <div className='col-lg-6 pt-lg-0 pt-5 display-12'>
                    <div className='d-flex justify-content-center'>
                        {stemrobotics.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid aeronautics_aerospace' />
                        ))}
                    </div>
                </div>
                <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5'>
                    <h2 className='pt-lg-5 display-12 lab_heading'><b>STEM & Robotics Lab</b></h2>
                    <p className='pt-2 text-black fs-5'>The STEM & Robotics Lab is a dynamic learning environment where students engage with science, technology, engineering, and mathematics through hands-on robotics projects. It fosters creativity, critical thinking, and practical problem-solving skills by integrating cutting-edge technology and collaborative activities.</p>
                    <div className='d-flex gap-3 pt-5'>
                        {/* <button className='lab_button_1'>Watch video</button> */}
                        <button className='lab_button_2'>
                            <Link to='/stem-and-robotics' className='lab_button_2'>
                                View more
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
            <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5'>
                    <h2 className='pt-lg-5 display-12 lab_heading'><b>Aeronautics & Aerospace Lab</b></h2>
                    <p className='pt-2 text-black fs-5'>The Aeronautics & Aerospace Lab is a cutting-edge facility dedicated to the study and innovation of flight and space exploration. It provides hands-on experience with advanced technologies, simulations, and design tools, empowering students to develop and test aerospace systems and concepts.</p>
                    <div className='d-flex gap-3 pt-5'>
                        {/* <button className='lab_button_1'>Watch video</button> */}
                        <Link to='/aeronautics-and-aerospace'>
                            <button className='lab_button_2'>View more</button>
                        </Link>
                    </div>
                </div>
                <div className='col-lg-6 pt-lg-0 pt-5 display-12'>
                    <div className='d-flex justify-content-center'>
                        {aeronauticsaerospace.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid aeronautics_aerospace' />
                        ))}
                    </div>
                </div>
            </div>
            <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                <div className='col-lg-6 pt-lg-0 pt-5 display-12'>
                    <div className='d-flex justify-content-center'>
                        {aiml.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid aeronautics_aerospace' />
                        ))}
                    </div>
                </div>
                <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5'>
                    <h2 className='pt-lg-5 display-12 lab_heading'><b>AI & ML Lab</b></h2>
                    <p className='pt-2 text-black fs-5'>The AI & ML Lab is a cutting-edge facility dedicated to the exploration and application of artificial intelligence and machine learning technologies. Equipped with advanced computing resources and software, the lab provides a collaborative environment for students and researchers to develop innovative solutions. It offers hands-on projects, workshops, and courses that enhance understanding of AI algorithms, data analysis, and real-world applications, preparing participants for the future of technology.</p>
                    <div className='d-flex gap-3 pt-5'>
                        {/* <button className='lab_button_1'>Watch video</button> */}
                        <Link to='/ai-and-ml'>
                            <button className='lab_button_2'>View more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
