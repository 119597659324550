import React from 'react'; 
import '../css/Cards.css';

const Cards = () => {  
  const FadeInSection = ({ children }) => {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });

      const { current } = domRef;
      observer.observe(current);
      return () => observer.unobserve(current);
    }, []);

    return (
      <div className={`fade-in-section ${isVisible ? "is-visible" : ""}`} ref={domRef}>
        {children}
      </div>
    );
  };

  const images = {
    aeromodelling: { src: '/Aeronautics_and_Aerospace/images/Aeromodelling - OBJ.png', alt: 'Aeromodelling Image 1' },
    dronetraining: { src: '/Aeronautics_and_Aerospace/images/Drone Training - OBJ.png', alt: 'Drone Training Image 1' },
    rcplane: { src: '/Aeronautics_and_Aerospace/images/RC Plane Workshop - OBJ.png', alt: 'RC Plane Image 1' },
  };

  return (
    <> 
  <section className='section card-container'> 
    <div className='container '>  
      <div className="row box-main d-flex align-items-center justify-content-center">
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 box-inner" > 
          <img className="img-fluid aeromodelling-img" src={images.aeromodelling.src} alt={images.aeromodelling.alt} /> 
          <FadeInSection><p className='fw-bold py-4 box-content rounded-5' style={{backgroundColor:'hsla(260, 100%, 67%,0.5)'}}>Aero modelling</p></FadeInSection>
        </div>
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 box-inner" >
          <img className="img-fluid dronetraining-img" src={images.dronetraining.src} alt={images.dronetraining.alt} />
          <FadeInSection><p className='fw-bold py-4 box-content rounded-5' style={{backgroundColor:'hsla(346, 100%, 54%,0.5) '}}>Drone Training</p></FadeInSection>
        </div>
        <div className="col-4 col-sm-3 col-md-3 col-lg-3 box-inner" >
          <img className="img-fluid rcplane-img" src={images.rcplane.src} alt={images.rcplane.alt} />
          <FadeInSection><p className='fw-bold py-4 box-content rounded-5' style={{backgroundColor:'hsla(170, 75%, 41%,0.5)'}}>RC Plane Workshop</p></FadeInSection>
        </div>
      </div>  
      </div> 
      </section>
    </>
  );
};

export default Cards;
