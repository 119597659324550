import React from 'react'
import './ourproducts.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function OurProducts_1() {

    const ourproductscover = [
        {
            src: '/Our Products/Our_products_cover.jpg',
            alt: 'ourproductscover',
        },
    ]

    const ourproductsinnovators = [
        {
            src: '/Our Products/INNOVATORS.png',
            alt: 'ourproductsinnovators',
        },
    ]

    const productImgs = [

        {
            heading: "MHI - Basic STEM Kit",
            src: '/Our Products/BOX.png',
            alt: 'productbox',
            list: ["15+ Components.", "25+ Projects.", "Customized HAT Module."],
            description: "Our kit includes a wide range of unique components, each selected for its educational value and ease of use. With these components, you can build and experiment with countless projects, from simple circuits to more complex systems.",
            specification: "Microcontroller | Specialized Module | Power Accessories | Display Units | Motors and Connectors | Audio Output Device | Sensors | Magnetic Components | Writing Materials | Connecting Wires | Motor Driver | Environmental Sensors | Lighting Components | Position Sensors | Control Buttons | Angle Sensors.",
        },
        {
            heading: "MHI - Advanced STEM Kit",
            src: '/Our Products/Products_pics/Intellect Advanced Stem Kit Box.png',
            alt: 'productbox',
            list: ["30+ Components.", "50+ Projects.", "Customized HAT Module."],
            description: "This kit is packed with components chosen to support more complex and innovative projects, from detailed circuits to sophisticated systems.",
            specification: "Microcontroller | Specialized Module | Power Accessories | Display Units | Motors and Connectors | Audio Output Device | Sensors | Magnetic Components | Writing Materials | Connecting Wires | Motor Driver | Environmental Sensors | Lighting Components | Position Sensors | Control Buttons | Angle Sensors.",
        },
        {
            heading: "MHI - Wi-Fi Car Kit",
            src: '/Our Products/Products_pics/Wifi Car.png',
            alt: 'productbox',
            list: ["10+ Components.", "20+ Projects.", "Wi-Fi Module."],
            description: "With components selected to simplify the learning of wireless communication, you can create and customize your own Wi-Fi-controlled robotic cars.",
            specification: "Microcontroller | Wi-Fi Module | Motors and Connectors | Sensors | Magnetic Components | Writing Materials | Connecting Wires | Motor Driver | Lighting Components | Control Buttons",
        },
        {
            heading: "MHI - Robotic Arm Kit",
            src: '/Our Products/Products_pics/Robotic arm.png',
            alt: 'productbox',
            list: ["12+ Components.", "18+ Engaging Projects.", "Control Module."],
            description: "With a variety of components, this kit helps you construct and program robotic arms, offering endless opportunities for learning and creativity.",
            specification: "Microcontroller | Control Module | Motors and Connectors | Sensors | Magnetic Components | Writing Materials | Connecting Wires | Motor Driver | Position Sensors | Control Buttons",
        },
        {
            heading: "MHI - Humanoid Robot Kit",
            src: '/Our Products/Products_pics/Humanoid Bot.png',
            alt: 'productbox',
            list: ["18+ Components.", "22+ Interactive Projects.", "Humanoid Module."],
            description: "With components designed to support human-like robotic functions, this kit helps you understand the exciting applications of humanoid robotics.",
            specification: "Microcontroller | Humanoid Module | Motors and Connectors | Sensors | Magnetic Components | Writing Materials | Connecting Wires | Motor Driver | Environmental Sensors | Lighting Components | Position Sensors | Control Buttons | Angle Sensors",
        },

    ]

    const aviationKit = [

        {
            heading: "MHI - Quadcopter Kit",
            src: '/Our Products/Products_pics/Quadcopter.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "Robust Construction.", "Enhanced Features.", "Versatile Mounting System.", "Tested Performance."],
            description: "The MHIQ1 Kit is an affordable, lightweight, and durable carbon fiber professional drone kit that's quick to assemble. It includes the MHIQ1 Frame Kit, along with motors, ESCs, power distribution boards, and propellers.",
            specification: "Frame | Battery | BLDC Motor | ESC | Flight Controller | GPS Module | IMU Sensor | Lipo Voltage Checker | Paper Tape | Propeller Blades | Propeller Guard | Allen Key set | Transmitter & Receiver Wire Cutter | Power Module | Zip Ties | MH Cockpit Sticker | MAV link Wireless module",
        },
        {
            heading: "MHI - Hexacopter Kit",
            src: '/Our Products/Products_pics/Hexacopter.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "Robust Construction.", "Enhanced Features.", "Versatile Mounting System.", "Tested Performance."],
            description: "The MHIH1 Kit is an affordable, lightweight, and durable carbon fiber professional drone kit that's quick to assemble. It includes the MHIH1 Frame Kit, along with motors, ESCs, power distribution boards, and propellers.",
            specification: "Frame | Battery | BLDC Motor | ESC | Flight Controller | GPS Module | IMU Sensor | Lipo Voltage Checker | Paper Tape | Propeller Blades | Propeller Guard | Allen Key set | Transmitter & Receiver | Wire Cutter | Power Module | Zip Ties | MH Cockpit Sticker | MAV link Wireless module",
        },
        {
            heading: "MHI - Nano Drone Kit",
            src: '/Our Products/Products_pics/Nanodrone.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "Robust Construction.", "Enhanced Features.", "Versatile Mounting System.", "Tested Performance."],
            description: "The MHIN1 drone is an excellent and affordable kit that lets you build your own Nano-drone without needing advanced mechanical skills or soldering expertise. It's a great way to understand the various components of a drone, which are surprisingly simple. Assembling the drone is a fun and exciting process, and once completed, you'll have a highly functional quadcopter that's enjoyable to fly.",
            specification: "3D Printed Frame | Battery | BLDC Motor | Flight Controller | Paper Tape | Propeller Blades | Propeller Guard | Screw Set/Allen Key set | Transmitter & Receiver mechanism | Wire Cutter | Zip Ties | MH Cockpit Sticker",
        },
        {
            heading: "MHI - Glider Kit",
            src: '/Our Products/Products_pics/Glider.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "All required components included."],
            description: "The MHIG1 Kit is a fantastic and affordable introduction to the world of model aviation. Perfect for hobbyists of all ages, this kit allows you to build your own lightweight, durable glider from high-quality balsa wood. The kit includes precisely cut pieces that fit together easily, requiring only minimal assembly and no specialized tools or skills.",
            specification: "3D-Printed Chisel | 3D-Printed COG Stand | 3D-Printed Sander & Launcher | Clay | CA Glue | Glider Blueprint | Laser Cut BALSA Wood | Protractor | Scale | Bluetack | MH-Intellect Sticker",
        },
        {
            heading: "MHI - Foam Board RC plane Kit",
            src: '/Our Products/Products_pics/Foam board RC.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "Robust Construction.", "Enhanced Features.", "Versatile Mounting System.", "Tested Performance."],
            description: "The MHIFRC1 Kit is a perfect entry point into the exciting world of radio-controlled aviation. Designed for hobbyists of all skill levels, this kit allows you to build a lightweight, durable plane using high-quality foam board. The components are pre-cut for easy assembly, requiring minimal tools and no prior experience. Building the Foam Board RC Plane is both fun and educational, offering hands-on experience with aerodynamics and flight mechanics. Once assembled, the plane delivers impressive performance with stable, responsive flights, making it ideal for both beginners and experienced pilots. Its lightweight construction ensures gentle landings and durability, while the modular design allows for easy repairs and modifications.",
            specification: "Foam Board | Battery | BLDC Motor | Connecting Rods | Connecting Wires | Cross Filament Tape | ESC | Flex Kwik | Landing Gear | Wheels | Mini Servos | Transmitter & Receiver | Wire Cutter | Zip Ties | MH Cockpit Sticker | Paper Cutter | Scale",
        },
        {
            heading: "MHI - 3D Printed RC plane Kit",
            src: '/Our Products/Products_pics/3D Printed RC.png',
            alt: 'productbox',
            list: ["Affordable and Quick Assembly.", "Robust Construction.", "Enhanced Features.", "Tested Performance."],
            description: "The MHIFRC1 is an atypical bush plane, designed from scratch to maximize aerodynamic performance by leveraging the design freedom of additive manufacturing. Unlike other bush planes, this model is highly versatile: beginners can benefit from its low flying speed, docile stall, and durable landing gear when printed with LW-PLA. However, its low drag design and large control surfaces also allow for fast and aerobatic flying.",
            specification: "Airframe | Battery | BLDC Motor | Connecting Rods | Connecting Wires | Cross Filament Tape | ESC | Flex Kwik | Landing Gear | Wheels | Mini Servos | Transmitter & Receiver | Wire Cutter | Zip Ties | MH Cockpit Sticker",
        },

    ]

    const productwhyus = [
        {
            src: '/Our Products/BENEFITS.jpg',
            alt: 'productbox',
        },
    ]

    const responsive = {

        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },

    };
    const StudentsFeedback = [

        {
            name: 'Aishwarya', feedback: "I bought the STEM Kit for my son's birthday, and it's been a huge hit! The components are high-quality, and the user manual is incredibly detailed and easy to follow. We've already completed several projects together, and it's been a fantastic learning experience for both of us. Highly recommend!",
        },

        {
            name: 'Silvio Bellies', feedback: 'As a teacher, I found this STEM Kit to be an excellent resource for my classroom. The variety of sensors and modules allowed my students to explore different aspects of electronics and programming. The projects in the manual are well thought out and suitable for different skill levels. Great product!',
        },

        {
            name: 'Mufarak', feedback: "This STEM Kit has everything you need to get started with Arduino. The components are neatly organized, and the user manual provides step-by-step instructions that are easy to understand. I've learned so much already and can't wait to start the advanced projects. Fantastic kit for beginners and hobbyists alike.",
        },

        {
            name: 'Alfathah', feedback: 'Purchased this kit for a summer camp program, and it was a huge success! The kids loved working with the different components, and it sparked a lot of interest in STEM fields. The projects are fun and educational, and the support from the company has been excellent. Will definitely buy more in the future.',
        },

        {
            name: 'Sarath', feedback: "I've been using Arduino for a while, and this kit is one of the best I've seen for beginners. The selection of components is thoughtful, and the user manual is comprehensive. It's perfect for anyone looking to get hands-on experience with electronics and coding. Highly recommend for all ages!",
        },

    ];

    return (
        <div>
            <div>
                {ourproductscover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid our_products_cover' />
                ))}
            </div>
            <div className='container py-5 px-5'>
                <p>The <b>MH INTELLECT STEM KIT</b>  is designed to inspire creativity and innovation, making it the perfect tool for budding engineers and tech enthusiasts. Whether you're a student, educator, or hobbyist, this comprehensive kit provides everything you need to dive into the world of electronics and robotics.</p>
            </div>
            <div className='py-3'>
                <center>
                    {ourproductsinnovators.map((image, index) => (
                        <img key={index} src={image.src} alt={image.alt} className='img-fluid w-50' />
                    ))}
                </center>
            </div>
            {productImgs.map((product, index) => (
                <div key={index}>
                    <center className=''>
                        <div class="container">
                            <div class="row justify-content-center pt-5 pb-5 z-1">
                                <div class="col-12 col-md-9 col-lg-6 pt-5 pb-5 text-center our_products_orange">
                                    <h1><b>{product.heading}</b></h1>
                                </div>
                            </div>
                        </div>
                    </center>
                    <div className='product_gradient pb-5 py-5'>
                        <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                            <div className='col-lg-6 pt-lg-0 pt-5 display-12'>
                                <div className='d-flex justify-content-center'>
                                    <img src={product.src} alt={product.alt} className='img-fluid pt-5' />
                                </div>
                            </div>
                            <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5 aviation_kits_list'>
                                <ul>
                                    {product.list.map((item, id) => (
                                        <li key={id}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <p>{product.description}</p>
                            <p>{product.specification}</p>
                        </div>
                    </div>
                </div>
            ))}
            <div className='py-5 text-center'>
                <h1>Aviation Kit</h1>
            </div>
            {aviationKit.map((product, index) => (
                <div key={index}>
                    <center className=''>
                        <div class="container">
                            <div class="row justify-content-center pt-5 pb-5 z-1">
                                <div class="col-12 col-md-9 col-lg-6 pt-5 pb-5 text-center our_products_orange">
                                    <h1><b>{product.heading}</b></h1>
                                </div>
                            </div>
                        </div>
                    </center>
                    <div className='product_gradient pb-5 py-5'>
                        <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                            <div className='col-lg-6 pt-lg-0 pt-5 display-12'>
                                <div className='d-flex justify-content-center'>
                                    <img src={product.src} alt={product.alt} className='img-fluid pt-5' />
                                </div>
                            </div>
                            <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5 aviation_kits_list'>
                                <ul>
                                    {product.list.map((item, id) => (
                                        <li key={id}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div>
                            <p>{product.description}</p>
                            <p>{product.specification}</p>
                        </div>
                    </div>
                </div>
            ))}
            <div className='container product_why_us pt-5'>
                {productwhyus.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid pt-5' />
                ))}
            </div>
            <div className='product_purple'>
                <div className='product_white'>
                    {/* <div className='container pt-5 product_curve_bottom'>
                        <center>
                            <h1 className='text_orange'>Product Description.</h1>
                        </center>
                        <div className='pt-5 text_lightblue'>
                            <p>The ideal STEM kit offering a comprehensive practical learning experience to learn robotics and coding through 30+ projects.</p>
                            <ul>
                                <li>Making AI and Robotics Incredibly Fun.</li>
                                <li><b>25+ Projects</b> Explore the entire project development process, including construction, programming, and debugging.</li>
                                <li><b>21st-Century Skills</b> Instill vital 21st-century competencies like creativity, critical thinking, problem-solving, logic, and innovation.</li>
                            </ul>
                        </div>
                        <center>
                            <h1 className='text_orange'>Why It's Important for Kids?</h1>
                        </center>
                        <div className='pt-5 text_lightblue'>
                            <p>The ideal STEM kit offering a comprehensive practical learning experience to learn robotics and coding through 30+ projects.</p>
                            <ul>
                                <li>Fosters Creativity and Innovation.</li>
                                <li>Builds Critical Thinking and Problem-Solving Skills.</li>
                                <li>Enhances STEM Learning.</li>
                                <li>Develops Technical Skills.</li>
                                <li>Promotes Independent Learning.</li>
                                <li>Encourages Teamwork and Collaboration.</li>
                                <li>Provides a Fun and Engaging Learning Experience.</li>
                                <li>Prepares for Future Careers.</li>
                                <li>Boosts Confidence and Achievement.</li>
                                <li>Integrates Learning Across Disciplines.</li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                {/* <div className='container pt-5 pb-5'>
                    <div className="testimonial-slider-container">
                        <Slider {...settings}>
                            {testimonials.map((testimonial) => (
                                <div key={testimonial.id} className="testimonial-card">
                                    <div className="testimonial-image-container">
                                        <img src={testimonial.src} alt={testimonial.name} className="testimonial-image" />
                                    </div>
                                    <div className="testimonial-info">
                                        <p className="testimonial-name">{testimonial.name}</p>
                                        <p className="testimonial-review">{testimonial.review}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div> */}
                <div className='py-5'>
                    <Carousel
                        responsive={responsive}
                        autoPlay={true}
                        swipeable={true}
                        draggable={true}
                        showDots={true}
                        infinite={true}
                        partialVisible={false}
                        dotListClass="custom-dot-list-style"
                    >
                        {StudentsFeedback.map((imageUrl, index) => {
                            return (
                                <div className="slider py-5 text-white" key={index}>
                                    <center>
                                        <h6>{imageUrl.name}</h6>
                                        <div className='px-5'>
                                            <p className='px-5'>{imageUrl.feedback}</p>
                                        </div>
                                    </center>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
                <center>
                    <h1 className='text-white pb-5'>Happy Clients</h1>
                </center>
            </div>
        </div>
    )
}
