import React, { useState, useEffect } from 'react'
import './home.css'
import Marqueeleft from './Marqueeleft';
import Marqueeright from './Marqueeright';
import axios from 'axios';
import SchoolimgMarquee from './SchoolimgMarquee';
import { Container } from 'react-bootstrap';
import SchoollogoMarquee from './SchoollogoMarquee';
import { Link, useLocation } from 'react-router-dom';

export default function Home() {

    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#request-demo-form') {
            const element = document.getElementById('request-demo-form');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const homesection1img = [
        {
            src: '/Home images/C3 p.png',
            alt: 'homesection1img',
        },
    ]
    const trainingprogram = [
        {
            src: '/Home images/Front page copy.png',
            alt: 'homesection1img',
        },
    ]
    const trainingprogrammobile = [
        {
            src: '/Home images/C1 copy.png',
            alt: 'homesection1img',
        },
    ]
    const pinkstroke = [
        {
            src: '/Home images/pink stroke.png',
            alt: 'homesection1img',
        },
    ]

    const homerow1 = [

        {
            src: '/Home images/row_1/Plane.jpg', alt: 'MH Intellect', link: '/rc-plane'
        },
        {
            src: '/Home images/row_1/Glider.jpg', alt: 'MH Intellect', link: '/glider'
        },
        {
            src: '/Home images/row_1/Drone.jpg', alt: 'MH Intellect', link: '/drone'
        },
        {
            src: '/Home images/row_1/Cansat.jpg', alt: 'MH Intellect', link: '/cansat'
        },

    ]

    const duplicatedRow1 = [...homerow1, ...homerow1];

    const homerow2 = [

        {
            src: '/Home images/row_2/IOT.jpg', alt: 'MH Intellect', link: '/IOT'
        },
        {
            src: '/Home images/row_2/Robotics.jpg', alt: 'MH Intellect', link: '/robotics'
        },
        {
            src: '/Home images/row_2/Advanced Robotics.jpg', alt: 'MH Intellect', link: '/advanced-robotics'
        },
        {
            src: '/Home images/row_2/Coding.jpg', alt: 'MH Intellect', link: '/coding'
        },

    ]

    const duplicatedRow2 = [...homerow2, ...homerow2];

    const homerow3 = [

        {
            src: '/Home images/row_3/ML.jpg', alt: 'MH Intellect', link: '/machine-learning'
        },
        {
            src: '/Home images/row_3/AI.jpg', alt: 'MH Intellect', link: '/artificial-intelligence'
        },
        {
            src: '/Home images/row_3/DIY Projects.jpg', alt: 'MH Intellect', link: '/diy-projects'
        },
        {
            src: '/Home images/row_3/Data Analytics.jpg', alt: 'MH Intellect', link: '/data-analytics'
        },

    ]

    const duplicatedRow3 = [...homerow3, ...homerow3];

    const globeimg = [
        {
            src: '/Home images/Icons/Globe.png',
            alt: 'trainingprogram',
        },
    ]
    const schoolimg = [
        {
            src: '/Home images/Icons/School.png',
            alt: 'homesection1img',
        },
    ]
    const studentsimg = [
        {
            src: '/Home images/Icons/Students.png',
            alt: 'homesection1img',
        },
    ]
    const experimentsimg = [
        {
            src: '/Home images/Icons/Experiments.png',
            alt: 'homesection1img',
        },
    ]
    const isoimg = [
        {
            src: '/Home images/Icons/ISO.png',
            alt: 'homesection1img',
        },
    ]
    const schoolimgmarquee = [

        {
            src: '/Home images/School_images/KRM Perambur Schl.jpg',
            alt: 'homesection1img',
            school_name: 'Kaligi Ranganathan Montford Matric Hr Sec School',
            cardcolour: 'card_1',
            school_intro: 'Empower the next generation of innovators with our STEAM-based education initiatives in schools, fostering creativity, critical thinking, and problem-solving skills for a future driven by science, technology, engineering, arts, and mathematics.',
        },
        {
            src: '/Home images/School_images/KRM Kolathur Schl.jpg',
            alt: 'homesection1img',
            school_name: 'KRM Public School',
            cardcolour: 'card_2',
            school_intro: 'Empowering young scholars at Kaligi Ranganathan Montford Vidhyashram with a dynamic STEAM-based curriculum, fostering a love for exploration and interdisciplinary thinking.',
        },
        {
            src: '/Home images/School_images/KRM Ayanavaram Matric HR Sec Schl.jpg',
            alt: 'homesection1img',
            school_name: 'Kaligi Ranganathan Montford Vidhyashram',
            cardcolour: 'card_3',
            school_intro: 'Nurturing budding innovators at Good Word Public School with an immersive STEAM-based curriculum, cultivating a foundation for creative problem-solving and future leadership in diverse fields.',
        },
        {
            src: '/Home images/School_images/Good Word Public Schl-Red Hills.jpg',
            alt: 'homesection1img',
            school_name: 'Good Word Public School',
            cardcolour: 'card_4',
            school_intro: 'Empower the next generation of innovators with our STEAM-based education initiatives in schools, fostering creativity, critical thinking, and problem-solving skills for a future driven by science, technology, engineering, arts, and mathematics.',
        },

    ]

    const duplicatedschoolmarquee = [...schoolimgmarquee, ...schoolimgmarquee];

    const schoollogomarquee = [

        {
            src: '/Home images/School_LOGO/KRM Matriculation Schl Logo.jpg',
            alt: 'MH Intellect'
        },
        {
            src: '/Home images/School_LOGO/KRM Public Schl Logo.jpg',
            alt: 'MH Intellect'
        },
        {
            src: '/Home images/School_LOGO/KRM Vidhyasharam Logo.jpg',
            alt: 'MH Intellect'
        },
        {
            src: '/Home images/School_LOGO/Good words Schl Logo.jpg',
            alt: 'MH Intellect'
        },

    ]

    const duplicatedschoollogomarquee = [...schoollogomarquee, ...schoollogomarquee];

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [number, setNumber] = useState('');
    const [countrycode, setCountrycode] = useState('');
    const [designation, setDesignation] = useState('');
    const [school, setSchool] = useState('');
    const [state, setState] = useState('');
    const [schedule, setSchedule] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const demoRequest = async () => {
        // Check if any required field is empty
        const requiredFields = [name, mail, designation, countrycode, number, school, state, schedule];
        const hasEmptyFields = requiredFields.some(field => field === '');
        setIsButtonDisabled(true);

        if (hasEmptyFields) {
            alert('Please fill in all required fields.');
            return;
        }

        try {
            const newData = {
                Name: name,
                Email: mail,
                Designation: designation,
                Country_code: countrycode,
                Mobile_Number: number,
                School: school,
                State: state,
                Shedule: schedule
            };
            await axios.post('https://sheetdb.io/api/v1/cfdgft5tcyrje', newData);
            setName('');
            setMail('');
            setNumber('');
            setCountrycode('');
            setDesignation('');
            setSchool('');
            setState('');
            setSchedule('');
            // alert("Your form has been submitted successfully! Our team will contact you within 24 hours!");
        } catch (error) {
            console.error('Error in Submission', error);
        }
        // window.location.href = '/thank_you';
        // window.location.href = '/contact';
    };

    return (
        <div>
            {/* <Header /> */}
            <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center gradient px-4'>
                <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5'>
                    <h2 className='pt-lg-5 text-white display-12 home_cover_heading'><b>Empowering Future Innovators</b></h2>
                    {/* <p className='pt-2 text-white fs-4'>STEMpedia is revolutionizing the way K-12 students learn about 21st-century skills, AI, and computer education with an exciting and comprehensive approach.</p> */}
                    <p className='pt-2 text-white fs-5'>Welcome to MH Intellect, where we're committed to cultivating the leaders of tomorrow. Our innovative programs focus on Aeronautics, Aerospace, STEM & Robotics, as well as AI & ML.</p>
                    <p className='pt-2 text-white fs-5'>Interactive workshops and projects, and customized lab setups in schools for students to learn in a collaborative environment that's both engaging and educational.</p>
                    <p className='pt-2 text-white fs-5'>Join us today and ignite your child's future!</p>
                    <div className='d-flex gap-3 pt-5'>
                        <a href="https://www.youtube.com/watch?v=AoW2VQKuj9c" target='_blank'><button className='home_button'>Watch a video</button></a>
                        <a href="/#request-demo-form"><button className='home_button'>Book a demo</button></a>
                    </div>
                </div>
                <div className='col-lg-6 pt-lg-0 pt-5 display-12 position-relative'>
                    <div className='d-flex justify-content-center'>
                        {homesection1img.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid home_img_1' />
                        ))}
                    </div>
                </div>
            </div>
            {/* <Counter /> */}
            {/* <div className='container'></div> */}
            <div className='container py-5'>
                <div className='d-flex justify-content-lg-around align-items-center py-5'>
                    <div className='align-items-center'>
                        <center>
                            {globeimg.map((image, index) => (
                                <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                            ))}
                            <div className='pt-3'>
                                <p><b>250+</b><br />Hours of Learning</p>
                            </div>
                        </center>
                    </div>
                    <div>
                        <center>
                            {schoolimg.map((image, index) => (
                                <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                            ))}
                            <div className='pt-3'>
                                <p><b>30+</b><br />Classroom Engaged</p>
                            </div>
                        </center>
                    </div>
                    <div>
                        <center>
                            {studentsimg.map((image, index) => (
                                <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                            ))}
                            <div className='pt-3'>
                                <p><b>1,000+</b><br />Students Inspired</p>
                            </div>
                        </center>
                    </div>
                    <div>
                        <center>
                            {experimentsimg.map((image, index) => (
                                <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                            ))}
                            <div className='pt-3'>
                                <p><b>70+</b><br />Practical Learning</p>
                            </div>
                        </center>
                    </div>
                </div>
            </div>
            <Container>
                <div className='d-none d-lg-block'> {/* Hide on small devices */}
                    <center>
                        {trainingprogram.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                        ))}
                    </center>
                </div>
                <div className='d-block d-lg-none'> {/* Hide on large devices */}
                    <center>
                        {trainingprogrammobile.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                        ))}
                    </center>
                </div>
            </Container>
            <div className='container pt-2 home_headings_flex'>
                <div>
                    <Link to='/aeronautics-and-aerospace' className='aeronautics'>
                        <h4>Aeronautics</h4>
                    </Link>
                </div>
                <div>
                    <h4 className='separator'>|</h4>
                </div>
                <div>
                    <Link to='/aeronautics-and-aerospace' className='aerospace'>
                        <h4>Aerospace</h4>
                    </Link>
                </div>
                <div>
                    <h4 className='separator'>|</h4>
                </div>
                <div>
                    <Link to='/ai-and-ml' className='aerospace'>
                        <h4 className='ai-ml'>AI & ML</h4>
                    </Link>
                </div>
                <div>
                    <h4 className='separator'>|</h4>
                </div>
                <div>
                    <Link to='/stem-and-robotics' className='robotics'>
                        <h4>STEM & Robotics</h4>
                    </Link>
                </div>
            </div>
            <div className='container py-5'>
                <center>
                    {pinkstroke.map((image, index) => (
                        <img key={index} src={image.src} alt={image.alt} className='img-fluid w-25' />
                    ))}
                </center>
            </div>
            <div class="container text-center">
                <h1 class="text_under_pinkstroke">Breathing existence into their miniature realm of imagination.</h1>
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8">
                        <p class="px-3 pt-3">MH Intellect is a specialized team dedicated to revolutionizing next-generation education in aerospace, AI & ML, STEM, hospitality, agriculture, and robotics. We focus on creating an experience-driven, accessible, and child-friendly approach for young minds. By collaborating with industry experts, educators, and leading institutions, MH Intellect has developed a student-focused platform to equip learners with essential 21st-century skills.</p>
                    </div>
                </div>
            </div>
            <div className='text-center pt-3 align-item-center'>
                <h4>MH Intellect is backed by</h4>
                {isoimg.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                ))}
            </div>
            <div className='pt-3'>
                <SchoolimgMarquee images={duplicatedschoolmarquee} />
            </div>
            <div class="container">
                <div class="text-center pt-5 px-3">
                    <h2><b>Fostering the Potential of Young Minds through Future-Ready Skills</b></h2>
                    <p class="px-lg-5 pt-3">MH intellect has changed the perspective of learning for the upcoming generation by curating innovative learning methods for AI, STEM,Aerospace and Robotics. Through dynamic practical sessions, MH Intellect ignites the curiosity of middle-school students, providing them with hands-on experiences that inspire and prepare them for the competitive technological advancements of the future.</p>
                </div>
            </div>
            <div className='pt-5'>
                <div className='pt-2'>
                    <Marqueeleft images={duplicatedRow1} />
                </div>
                <div className='pt-2'>
                    <Marqueeright images={duplicatedRow2} />
                </div>
                <div className='pt-2'>
                    <Marqueeleft images={duplicatedRow3} />
                </div>
            </div>
            <div class="container mt-5">
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/Ft5FkA8uyyA?si=EWjRPPJwnd2D0lqv" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/SU3CV0ocmYI?si=RSluD4Y0wtn6XHut" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                {/* <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/UzFnn-gf28k?si=vNTWLiLRl-Zrn-im" allowfullscreen></iframe>
                        </div>
                    </div> */}
                    <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/Lkzk_CYNEKo?si=TxJSaoBPPj7MI1MI" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" id="request-demo-form">
                <div class="text-center pt-5 px-3 px-md-5">
                    <h2><b>Schedule your demonstration Today!</b></h2>
                    <p class="px-2 px-md-5 pt-3">Are you prepared to explore the capabilities of AI and Robotics? Secure a complimentary demonstration of our AI and Robotics ecosystem now!</p>
                </div>
            </div>
            <div class='container-fluid px-lg-5 px-sm-3 px-3 py-5 my-5 gradient'>
                <div class='row justify-content-between align-items-center'>
                    <div></div>
                    <div class='col-lg-6 px-lg-5 home_form_background'>
                        <div class='form_heading'>
                            <h3><b>Unleash Your Kids Potential!</b></h3>
                        </div>
                        {/* <form> */}
                        <div class="flex-container d-flex gap-3 pt-3">
                            <div class='form-group flex-item w-100'>
                                <label for='fullName'>Full Name</label>
                                <input type='text' class='form-control mt-2' id='fullName' placeholder='Enter Your Full Name' value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div class='form-group flex-item w-100'>
                                <label for='email'>Email</label>
                                <input type='email' class='form-control mt-2' id='email' placeholder='Email' value={mail} onChange={(e) => setMail(e.target.value)} />
                            </div>
                        </div>
                        <div class="flex-container pt-3">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="designation">Designation</label>
                                        <select class="form-control mt-2" id="designation" value={designation} onChange={(e) => setDesignation(e.target.value)}>
                                            <option value="" disabled selected hidden>Designation</option>
                                            <option>Educator</option>
                                            <option>Management</option>
                                            <option>Principal</option>
                                            <option>Parent</option>
                                            <option>Trainer</option>
                                            <option>Student</option>
                                            <option>Others</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="d-flex">
                                        <div class="form-group me-2">
                                            <label for="countryCode">Code</label>
                                            <select class="form-control mt-2" id="countryCode" value={countrycode} onChange={(e) => setCountrycode(e.target.value)}>
                                                <option value="" disabled selected hidden>+</option>
                                                <option>+91</option>
                                                <option>+971</option>
                                                <option>+966</option>
                                                <option>+974</option>
                                                <option>+965</option>
                                                <option>+94</option>
                                                <option>+960</option>
                                                <option>+1</option>
                                                <option>+7</option>
                                                <option>+20</option>
                                                <option>+27</option>
                                                <option>+30</option>
                                                <option>+31</option>
                                                <option>+32</option>
                                                <option>+33</option>
                                                <option>+34</option>
                                                <option>+36</option>
                                                <option>+39</option>
                                                <option>+40</option>
                                                <option>+41</option>
                                                <option>+43</option>
                                                <option>+44</option>
                                                <option>+45</option>
                                                <option>+46</option>
                                                <option>+47</option>
                                                <option>+48</option>
                                                <option>+49</option>
                                                <option>+51</option>
                                                <option>+52</option>
                                                <option>+53</option>
                                                <option>+54</option>
                                                <option>+55</option>
                                                <option>+56</option>
                                                <option>+57</option>
                                                <option>+58</option>
                                                <option>+60</option>
                                                <option>+61</option>
                                                <option>+62</option>
                                                <option>+63</option>
                                                <option>+64</option>
                                                <option>+65</option>
                                                <option>+66</option>
                                                <option>+81</option>
                                                <option>+82</option>
                                                <option>+84</option>
                                                <option>+86</option>
                                                <option>+90</option>
                                                <option>+92</option>
                                                <option>+93</option>
                                                <option>+95</option>
                                                <option>+98</option>
                                                <option>+211</option>
                                                <option>+212</option>
                                                <option>+213</option>
                                                <option>+216</option>
                                                <option>+218</option>
                                                <option>+220</option>
                                                <option>+221</option>
                                                <option>+222</option>
                                                <option>+223</option>
                                                <option>+224</option>
                                                <option>+225</option>
                                                <option>+226</option>
                                                <option>+227</option>
                                                <option>+228</option>
                                                <option>+229</option>
                                                <option>+230</option>
                                                <option>+231</option>
                                                <option>+232</option>
                                                <option>+233</option>
                                                <option>+234</option>
                                                <option>+235</option>
                                                <option>+236</option>
                                                <option>+237</option>
                                                <option>+238</option>
                                                <option>+239</option>
                                                <option>+240</option>
                                                <option>+241</option>
                                                <option>+242</option>
                                                <option>+243</option>
                                                <option>+244</option>
                                                <option>+245</option>
                                                <option>+246</option>
                                                <option>+247</option>
                                                <option>+248</option>
                                                <option>+249</option>
                                                <option>+250</option>
                                                <option>+251</option>
                                                <option>+252</option>
                                                <option>+253</option>
                                                <option>+254</option>
                                                <option>+255</option>
                                                <option>+256</option>
                                                <option>+257</option>
                                                <option>+258</option>
                                                <option>+260</option>
                                                <option>+261</option>
                                                <option>+262</option>
                                                <option>+263</option>
                                                <option>+264</option>
                                                <option>+265</option>
                                                <option>+266</option>
                                                <option>+267</option>
                                                <option>+268</option>
                                                <option>+269</option>
                                                <option>+290</option>
                                                <option>+291</option>
                                                <option>+297</option>
                                                <option>+298</option>
                                                <option>+299</option>
                                                <option>+350</option>
                                                <option>+351</option>
                                                <option>+352</option>
                                                <option>+353</option>
                                                <option>+354</option>
                                                <option>+355</option>
                                                <option>+356</option>
                                                <option>+357</option>
                                                <option>+358</option>
                                                <option>+359</option>
                                                <option>+370</option>
                                                <option>+371</option>
                                                <option>+372</option>
                                                <option>+373</option>
                                                <option>+374</option>
                                                <option>+375</option>
                                                <option>+376</option>
                                                <option>+377</option>
                                                <option>+378</option>
                                                <option>+380</option>
                                                <option>+381</option>
                                                <option>+382</option>
                                                <option>+385</option>
                                                <option>+386</option>
                                                <option>+387</option>
                                                <option>+389</option>
                                                <option>+420</option>
                                                <option>+421</option>
                                                <option>+423</option>
                                                <option>+500</option>
                                                <option>+501</option>
                                                <option>+502</option>
                                                <option>+503</option>
                                                <option>+504</option>
                                                <option>+505</option>
                                                <option>+506</option>
                                                <option>+507</option>
                                                <option>+508</option>
                                                <option>+509</option>
                                                <option>+590</option>
                                                <option>+591</option>
                                                <option>+592</option>
                                                <option>+593</option>
                                                <option>+594</option>
                                                <option>+595</option>
                                                <option>+596</option>
                                                <option>+597</option>
                                                <option>+598</option>
                                                <option>+599</option>
                                                <option>+670</option>
                                                <option>+672</option>
                                                <option>+673</option>
                                                <option>+674</option>
                                                <option>+675</option>
                                                <option>+676</option>
                                                <option>+677</option>
                                                <option>+678</option>
                                                <option>+679</option>
                                                <option>+680</option>
                                                <option>+681</option>
                                                <option>+682</option>
                                                <option>+683</option>
                                                <option>+685</option>
                                                <option>+686</option>
                                                <option>+687</option>
                                                <option>+688</option>
                                                <option>+689</option>
                                                <option>+690</option>
                                                <option>+691</option>
                                                <option>+692</option>
                                                <option>+850</option>
                                                <option>+852</option>
                                                <option>+853</option>
                                                <option>+855</option>
                                                <option>+856</option>
                                                <option>+870</option>
                                                <option>+880</option>
                                                <option>+886</option>
                                                <option>+961</option>
                                                <option>+962</option>
                                                <option>+963</option>
                                                <option>+964</option>
                                                <option>+967</option>
                                                <option>+968</option>
                                                <option>+970</option>
                                                <option>+972</option>
                                                <option>+973</option>
                                                <option>+975</option>
                                                <option>+976</option>
                                                <option>+977</option>
                                                <option>+992</option>
                                                <option>+993</option>
                                                <option>+994</option>
                                                <option>+995</option>
                                                <option>+996</option>
                                                <option>+998</option>
                                                <option>+1242</option>
                                                <option>+1246</option>
                                                <option>+1264</option>
                                                <option>+1268</option>
                                                <option>+1284</option>
                                                <option>+1340</option>
                                                <option>+1345</option>
                                                <option>+1441</option>
                                                <option>+1473</option>
                                                <option>+1649</option>
                                                <option>+1664</option>
                                                <option>+1670</option>
                                                <option>+1671</option>
                                                <option>+1684</option>
                                                <option>+1721</option>
                                                <option>+1758</option>
                                                <option>+1767</option>
                                                <option>+1784</option>
                                                <option>+1787</option>
                                                <option>+1809</option>
                                                <option>+1829</option>
                                                <option>+1849</option>
                                                <option>+1868</option>
                                                <option>+1869</option>
                                                <option>+1876</option>
                                                <option>+1939</option>
                                            </select>
                                        </div>
                                        <div class="form-group flex-grow-1">
                                            <label for="phoneNumber">Phone Number</label>
                                            <input type="number" class="form-control mt-2" id="phoneNumber" placeholder="Phone Number" value={number} onChange={(e) => setNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='form-group pt-3'>
                            <label for='institution'>School / Institution</label>
                            <input type='text' class='form-control mt-2' id='institution' placeholder='School / Institution' value={school} onChange={(e) => setSchool(e.target.value)} />
                        </div>
                        <div class="flex-container">
                            <div class="row">
                                <div class="col-md-6 col-sm-12 form-group pt-3 d-flex flex-column">
                                    <label for='state'>State</label>
                                    <input type='text' class='form-control mt-2' id='state' placeholder='Enter State' value={state} onChange={(e) => setState(e.target.value)} />
                                </div>
                                <div class="col-md-6 col-sm-12 form-group pt-3 d-flex flex-column">
                                    <label for="schedule">Schedule demo for</label>
                                    <select class="form-control mt-2" id="schedule" value={schedule} onChange={(e) => setSchedule(e.target.value)}>
                                        <option value="" disabled selected hidden>Select Options</option>
                                        <option>MH Intellect Aerospace Program</option>
                                        <option>MH Intellect STEM Program</option>
                                        <option>MH Intellect Workshop</option>
                                        <option>MH Lab for Aeronautics and Aerospace</option>
                                        <option>MH Lab for STEM & Robotics</option>
                                        <option>MH Lab for Artificial Intelligence and Machine Learning</option>
                                        {/* <option>All</option> */}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <center>
                            <div class='form-group pt-3'>
                                <button type='submit' class='btn btn-primary' onClick={demoRequest}>Submit Request</button>
                            </div>
                        </center>
                        {/* </form> */}
                    </div>
                    <div class='col-lg-6 pt-lg-0 pt-sm-3 pt-5'>
                        <h1><b>Why Choose us?</b></h1>
                        <p>Our core is all about delivering the best and safe environment which provides the adequate resources to give your children an epic experience of learning. Our team makes sure that our little minds explore their capabilities and creativity and give them an one on one attention to ensure equal distribution of knowledge.</p>
                        <h3>Our State-of-the-Art Educational Labs</h3>
                        <ul>
                            <li>MH Lab for Aeronautics and Aerospace.</li>
                            <li>MH Lab for STEM & Robotics.</li>
                            <li>MH Lab for Artificial Intelligence and Machine Learning.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='container'>
                <center>
                    <h3><b>Schools Who Trust Us! Join Us for an Unparalleled Educational Experience!</b></h3>
                </center>
            </div>
            <div class="container py-5">
                <div class="row">
                    <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/UvglM4LnKMQ?si=ErkT84tmk3OTq3tY" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div class="col-md-6 mb-4">
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe width="100%" height="355" class="embed-responsive-item" src="https://www.youtube.com/embed/Gph-eOAnRvE?si=IK7B68tAd2KjU_w0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-3'>
                <SchoollogoMarquee images={duplicatedschoollogomarquee} />
            </div>
        </div>
    )
}
