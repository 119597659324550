import React from 'react'
import './OurGovernance.css'
import { Container, Row, Col } from 'react-bootstrap';

const ImageColumn = ({ src, alt, name, role }) => (
    <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
        <center>
            <div className="our_governance_img_hover">
                <img src={src} alt={alt} className='img-fluid our_governance_img' />
                <div className='bg-black text-white pb-3 our_governance_text'>
                    <p className='pt-3'><b>{name}</b></p>
                    <p><b>{role}</b></p>
                </div>
            </div>
        </center>
    </Col>
);

export default function OurGovernance() {

    const OurGovernanceImg = [

        {
            src: '/Our Governance/CEO.jpg',
            alt: 'Our Governance',
            name: 'Ahamed',
            role: 'CEO',
        },
        {
            src: '/Our Governance/Ashia - Director HR.jpg',
            alt: 'Our Governance',
            name: 'Ashia',
            role: 'Director HR',
        },
        {
            src: '/Our Governance/Haja.jpg',
            alt: 'Our Governance',
            name: 'Haja',
            role: 'Director Advisory Board',
        },
        {
            src: '/Our Governance/Alhaf Malik New.webp',
            alt: 'Our Governance',
            name: 'Alhaf Malik',
            role: 'CTO',
        },
        {
            src: '/Our Governance/yasir sir.jpg',
            alt: 'Our Governance',
            name: 'Ahamed Yasir',
            role: 'CFO',
        },
        {
            src: '/Our Governance/Yacqub Lye.webp',
            alt: 'Our Governance',
            name: 'Yacqub Lyer',
            role: 'Director Sales And Marketing - UAE',
        },

    ]

    const MHCockpitBoardMembers = [

        {
            src: '/Our Governance/Burhan Mohamed.jpg',
            alt: 'Our Governance',
            name: 'Burhan Mohamed',
            role: 'COO - GCC operations',
        },
        {
            src: '/Our Governance/Jehath.jpg',
            alt: 'Our Governance',
            name: 'Jehad Al Shehri',
            role: 'Director - GCC operations',
        },
        {
            src: '/Our Governance/KIRIAKOS KIRANOS.jpg',
            alt: 'Our Governance',
            name: 'Capt. Kiriakos Kiranos',
            role: 'Director European Operations',
        },
    
    ]

    const MHIntellectTeam = [

        {
            src: '/Our Governance/Sonam Mishra.jpg',
            alt: 'Our Governance',
            name: 'Sonam Mishra',
            role: 'Head of operations',
        },
        {
            src: '/Our Governance/VINOTH.jpg',
            alt: 'Our Governance',
            name: 'Vinoth',
            role: 'Head Program Delivery',
        },
        {
            src: '/Our Governance/Arun.jpg',
            alt: 'Our Governance',
            name: 'Arunachala Raam',
            role: 'Project Head',
        },
        {
            src: '/Our Governance/Santosh.jpg',
            alt: 'Our Governance',
            name: 'Santhosh',
            role: 'Instructor',
        },
        {
            src: '/Our Governance/Selva.jpg',
            alt: 'Our Governance',
            name: 'Selva',
            role: 'Instructor',
        },
        {
            src: '/Our Governance/Gowtham.jpg',
            alt: 'Our Governance',
            name: 'Gowtham',
            role: 'Instructor',
        },
        {
            src: '/Our Governance/Manikam.jpg',
            alt: 'Our Governance',
            name: 'Manickam',
            role: 'Instructor',
        },
    
    ]

    return (
        <div>
            <div className='py-5 text-center'>
                <h1>OUR GOVERNANCE.</h1>
            </div>
            <div className='pt-5'>
                <Container>
                    <Row>
                        {OurGovernanceImg.map((image, index) => (
                            <ImageColumn
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                name={image.name}
                                role={image.role}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
            <div className='py-5 text-center'>
                <h1>MH Cockpit Board Members.</h1>
            </div>
            <div className='pt-5'>
                <Container>
                    <Row>
                        {MHCockpitBoardMembers.map((image, index) => (
                            <ImageColumn
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                name={image.name}
                                role={image.role}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
            <div className='py-5 text-center'>
                <h1>MH Intellect Team.</h1>
            </div>
            <div className='pt-5'>
                <Container>
                    <Row>
                        {MHIntellectTeam.map((image, index) => (
                            <ImageColumn
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                name={image.name}
                                role={image.role}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
            <div id="contact"></div>
        </div>
    )
}
