import React from 'react'
import './ProjectPages.css'

export default function Glider() {

    const Glidercover = [
        {
            src: '/Projects/Projects/Glider 2.jpg',
            alt: 'Glider',
        },
    ]

    return (
        <div>
            <div>
                {Glidercover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Glider</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Construct and launch a glider, delving into aeromodelling principles of lift, balance, and
                        stability. Experience the joy of watching your creation soar gracefully through the air.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Aeromodelling Glider project focuses on designing and flying a model glider aircraft.
                        Participants explore principles of aerodynamics, balance, and stability as they build their
                        gliders from scratch. Utilising lightweight materials and efficient wing designs, they achieve
                        prolonged flight times and graceful soaring maneuvers. This project promotes
                        understanding of flight mechanics and appreciation for the art of aeromodelling.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Teaching Aid</b><br />
                                It is a useful teaching aid that
                                provides real-world examples of physics,
                                math, and engineering concepts. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Hobbyist Activity</b><br />
                                Building and piloting gliders is a fun and engaging hobby that
                                develops technical skill and inventiveness for enthusiasts. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Skill Development</b><br />
                                Participating in aeromodelling projects fosters
                                the developmentof fine motor skills, a grasp of aerodynamics,
                                teamwork, and project managementability. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Glider</span> project </p>
                        <p>The Aeromodelling Glider project has a wide future scope and great potential in a number
                            of areas,</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research</b></span></p>
                    <p className='pt-2'>The work here has the potential to provide the groundwork for
                        future investigations into sustainable aircraft technology and aerodynamics.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>UAV Development</b></span></p>
                    <p className='pt-2'>The design and development of more effective and ecologically
                        friendly unmanned aerial vehicles (UAVs) can benefit from insights gained from
                        glider programmes.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Educational Programmes</b></span></p>
                    <p className='pt-2'>As glider projects continue to progress, more extensive
                        and practical educational programmes that prepare students for careers in
                        aerospace and allied industries may be developed.</p>
                </div>
            </div>
        </div>
    )
}
