import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function SchoolProgramsCoverCarousel() {

    const SchoolProgramscover = [

        {
            src: '/School Programs Carousel/Cover/schoolprogram1.jpg',
            alt: 'School Programs Cover',
        },
        {
            src: '/School Programs Carousel/Cover/schoolprogram2.jpg',
            alt: 'School Programs Cover',
        },
        {
            src: '/School Programs Carousel/Cover/schoolprogram3.jpg',
            alt: 'School Programs Cover',
        },
        {
            src: '/School Programs Carousel/Cover/schoolprogram4.jpg',
            alt: 'School Programs Cover',
        },
        {
            src: '/School Programs Carousel/Cover/schoolprogram5.jpg',
            alt: 'School Programs Cover',
        },

    ];

    return (
        <Carousel>
            {SchoolProgramscover.map((image, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={image.src} alt={image.alt} className='img-fluid' style={{ marginRight: '20px' }} />
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SchoolProgramsCoverCarousel;