import React from 'react'
import './SchoolPrograms.css'
import SchoolProgramsCarousel1 from './SchoolPrograms Carousel/SchoolProgramsCarousel1'
import SchoolProgramsCarousel2 from './SchoolPrograms Carousel/SchoolProgramsCarousel2'
import SchoolProgramsCarousel3 from './SchoolPrograms Carousel/SchoolProgramsCarousel3'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SchoolProgramsCoverCarousel from './SchoolPrograms Carousel/SchoolProgramsCoverCarousel'

export default function SchoolPrograms() {

    const responsive = {

        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },

    };
    const StudentsFeedback = [

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/S.J. Mythreshwar.png', name: 'S.J. Mythreshwar', standard: '10th standard', feedback: 'The Summer Program was good. It was a very special session to me as they showed us many kits and we were able to practice with it.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Risshi.png', name: 'V.S. Risshi', standard: '7th standard', feedback: 'It was very great. We had a nice experience. Now today I Know lots of facts about aircrafts.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Venu Sudharshan.png', name: 'B. Venu Sudharshan', standard: '8th standard', feedback: 'I enjoyed and learned about aviation and STEM concepts. I would recommend my friends about this program.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/dharun rohith.png', name: 'N. Dharun Rohit', standard: '8th standard', feedback: 'This summer program made me to know about aviation, STEM programming and I learned to know about the sensor and how it works.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Hari vishvesh.png', name: 'S.P. Hari Vishvesh', standard: '9th standard', feedback: 'At the first we thought the classes would be boring but as the practical classes started, myself and my friends really enjoyed it. It was an amazing program.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Laksha.png', name: 'S. Laksha', standard: '8th standard', feedback: 'The classes were very interactive and it was a useful course. The topics and activities were enthusiastic and interesting. Everything was awesome and the instructors were just perfect.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Manikarnika.png', name: 'P. Manikarnika', standard: '8th standard', feedback: 'It is too interesting and there were many experiments. We got to learn about many facts and a lot of new things. It was really useful for me and we got to do all the projects on our own.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Kavignanamurugan.png', name: 'G. Kavignanamurugan', standard: '9th standard', feedback: 'This summer program did not go as planned. I thought it would be full of lectures and sleepiness, but all the session were fully interactive, consisting many experiments and projects. The classes made us proactive.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Mohamed Khalil.png', name: 'A. Mohammed Khalil', standard: '9th standard', feedback: 'This is a very good program for children or others to join during summer. This is very useful to us and this program gives us more knowledge about aviation, STEM, etc.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Abinav.png', name: 'J.N. Abinav', standard: '8th standard', feedback: 'It was a new experience. We did many new experiments and it was kind of fun and helped us to gain many new knowledge.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Pravanav.png', name: 'S. Pranav', standard: '8th standard', feedback: 'I was really glad about this summer program. It was very interesting, we understood what they taught in summer program and we were able to do many activities and experiments.',
        },

        {
            src: process.env.PUBLIC_URL + '/School Programs Carousel/Students/Aadhitya.png', name: 'L. Aadhithya', standard: '9th standard', feedback: 'It was an amazing workshop. This program must be made compulsory to all the students because this program was very useful to us.',
        },

    ];

    return (
        <div>
            <div>
                <SchoolProgramsCoverCarousel />
            </div>
            <div className='container text-center pt-5 MH_Intellect_Workshop'>
                <h1><b>MH Intellect Workshop</b></h1>
            </div>
            <div className='container-fluid px-lg-5 py-5 d-lg-flex flex-lg-row flex-column justify-content-lg-between align-items-center px-4'>
                <div className='col-lg-6 px-lg-5 pt-lg-0 pt-5 projects_page_description'>
                    <h2 className='pt-lg-5 Fly_High_with_Innovation'><b>Fly High with Innovation</b></h2>
                    <p className='pt-2 fs-5'>Our thoughtfully crafted programs provide practical learning experiences across various fields, including Aeronautics and aerospace, AI and ML, and STEM and robotics.</p>
                    <p className='pt-2 fs-5'>Explore our diverse range of customized in-school labs tailored to each program. Students will dive deep into these subjects, enjoying an exciting journey of discovery and creativity.</p>
                </div>
                <div className='col-lg-6 pt-lg-0 pt-5'>
                    <div className='d-flex justify-content-center'>
                        <div>
                            <div className='projects_page_note projects_blue_stickynote'>
                                <div className='projects_page_content'>
                                    <h3><b>Duration</b><br />
                                        15 Days<br />
                                        45+ Hours</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-5 py-5'>
                <div className='container text-center'>
                    <h1 className='school_program_heading_highlight'><b>MH Intellect <span>Aeronautical and Aerospace</span></b></h1>
                </div>
                <div className='container pt-3 projects_page_description'>
                    <p>Dive into the fascinating world of aeronautics and aerospace with our specialized program. Students will learn the fundamentals of flight, design, and engineering through hands-on projects and real-world applications.</p>
                </div>
                <div className='pt-3 text-center'>
                    <h3><b>Duration ( 80+ Hours)</b></h3>
                </div>
                <div className='container pt-5'>
                    <SchoolProgramsCarousel1 />
                </div>
            </div>
            <div className='pt-5 py-5'>
                <div className='container text-center'>
                    <h1 className='school_program_heading_highlight'><b>MH Intellect <span>AI & ML</span></b></h1>
                </div>
                <div className='container text-center'>
                    <h5><b>Shape the Future with Artificial Intelligence</b></h5>
                </div>
                <div className='container pt-3 projects_page_description'>
                    <p>Our AI & ML program introduces students to the transformative world of artificial intelligence and machine learning. Through a blend of theoretical knowledge and practical applications, students will gain insights into this rapidly evolving field.</p>
                </div>
                <div className='pt-3 text-center'>
                    <h3><b>Duration ( 20+ Hours)</b></h3>
                </div>
                <div className='container pt-5'>
                    <SchoolProgramsCarousel2 />
                </div>
            </div>
            <div className='pt-5 py-5'>
                <div className='container text-center'>
                    <h1 className='school_program_heading_highlight'><b>MH Intellect <span>STEM and Robotics</span></b></h1>
                </div>
                <div className='container text-center'>
                    <h5><b>Build the Future with STEM and Robotics</b></h5>
                </div>
                <div className='container pt-3 projects_page_description'>
                    <p>Our STEM and Robotics program encourages students to explore the wonders of science, technology, engineering, and mathematics through interactive projects and challenges.</p>
                </div>
                <div className='pt-3 text-center'>
                    <h3><b>Duration ( 60+ Hours)</b></h3>
                </div>
                <div className='container pt-5'>
                    <SchoolProgramsCarousel3 />
                </div>
            </div>
            <div className='container pt-5 py-5 projects_page_description'>
                <p>MH Intellect is dedicated to empowering young minds through practical learning experience. Our workshops offer a blend of theory and practical application, tailored for kids. Whether your child is passionate about aeronautics, artificial intelligence, or STEM and robotics, our programs are designed to spark interest and drive success. Join us for an engaging educational experience!</p>
            </div>
            <div className='school_programs_gradient py-5'>
                <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    infinite={true}
                    partialVisible={false}
                    dotListClass="custom-dot-list-style"
                >
                    {StudentsFeedback.map((imageUrl, index) => {
                        return (
                            <div className="slider" key={index}>
                                <center>
                                    <img src={imageUrl.src} className='w-50' alt="feedback" loading='eager' fetchpriority="high" />
                                    <h6>{imageUrl.name}</h6>
                                    <h6>{imageUrl.standard}</h6>
                                    <p className='px-5 pb-4'>{imageUrl.feedback}</p>
                                </center>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        </div>
    )
}
