import React from "react";
// import img from '../../images/AI-Robotics-Lab-Layout-Furniture-1-1-2.png'
import "./Aiandrobo.css";

const Aiandrobo = () => {
  return (
    <div>
      {/* <div>
       <img src={img} alt='Ailab' className='img1'/> 
      </div> */}
      <div className="aiandrobocontent">
        <div className="para1_robo">
          <h1>Center of Excellence in Robotics & STEM</h1>
          <p>
            The school's robotics lab incorporates machine learning, <br></br>
            sensor integration, and autonomous navigation, honing skills in<br></br>
            robot design,construction, and programming for future innovation leadership.
          </p>
          <button className="aibutton">What's Involved?</button>
        </div>
        <div className="pa">
          <div>
            <p className="pa1">Internet of things</p>
            <p className="pa2">Advance Robotics</p>
          </div>
          <div className="pad1">
            <p className="pa3">Robotics Programming</p>
            <p className="pa4">AR & VR</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aiandrobo;
