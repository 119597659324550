import React from 'react'
import './ProjectPages.css'

export default function QuadcopterDrone() {

    const QuadcopterDronecover = [
        {
            src: '/Projects/Projects/Drone.jpg',
            alt: 'QuadcopterDrone',
        },
    ]

    return (
        <div>
            <div>
                {QuadcopterDronecover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description '>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Quadcopter Drone</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Assemble and pilot your own quadcopter drone, mastering the intricacies of flight
                        dynamics and remote-control systems.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Quadcopter Drone project involves building a versatile aerial vehicle capable of
                        controlled flight. Using a combination of motors, propellers, and a flight controller, it
                        achieves stability and manoeuvrability. This project focuses on understanding
                        aerodynamics, electronics, and remote-control systems. Features may include altitude
                        hold, GPS navigation, and camera integration for aerial photography.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It's a great teaching tool that offers real-world examples of
                                physics, arithmetic, computer science, and engineering concepts.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Engagement of Enthusiasts</b><br />
                                Building and operating quadcopters is a fulfilling
                                pastime that fosters creativity
                                and improves technical proficiency for enthusiasts. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Skill Development</b><br />
                                Working with quadcopters fosters cooperation and project
                                management skills while enhancing knowledge of electronics, programming, and
                                aerodynamics. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Quadcopter Drone</span> project </p>
                        <p>has a broad future reach and great potential in a number of
                            disciplines, including</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research</b></span></p>
                    <p className='pt-2'>It could serve as a springboard for more in-depth studies on
                        robotics, autonomous systems, and UAV technology.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Commercial Applications</b></span></p>
                    <p className='pt-2'>Innovations in commercial drone applications, such as
                        delivery services, aerial photography, and agriculture, may result from insights
                        gained from quadcopter initiatives.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Educational Programmes</b></span></p>
                    <p className='pt-2'>As quadcopter technology continues to progress, educational
                        programmes can be improved and students better prepared for professions in the IT,
                        robotics, and aerospace industries.
                        Drones can be used for vital tasks including environmental monitoring, search and
                        rescue missions, and disaster response. They can also be used for disaster
                        management, giving real-time data and improving operational effectiveness.</p>
                </div>
            </div>
        </div>
    )
}
