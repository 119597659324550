import React from 'react'
import './projects.css'
import { Container, Row, Col } from 'react-bootstrap';

const ImageColumn = ({ src, alt, heading, url }) => (
    <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
        <center>
            <div className="p-3 project_img_hover">
                <a href={url} rel="noopener noreferrer">
                    <img src={src} alt={alt} className='img-fluid our_products_cover project_img' />
                </a>
                <p className='pt-3'><b>{heading}</b></p>
            </div>
        </center>
    </Col>
);

export default function Projects() {

    const projectscover = [
        {
            src: '/Projects/PROJECTS COVER.jpg',
            alt: 'projectscover',
        },
    ]

    const projectsimg = [

        {
            src: '/Home images/row_1/Plane.jpg',
            alt: 'RC Plane',
            heading: 'RC Plane',
            url: '/rc-plane',
        },
        {
            src: '/Home images/row_1/Glider.jpg',
            alt: 'Glider',
            heading: 'Glider',
            url: '/glider',
        },
        {
            src: '/Home images/row_1/Drone.jpg',
            alt: 'Drone',
            heading: 'Drone',
            url: '/drone',
        },
        {
            src: '/Home images/row_1/Cansat.jpg',
            alt: 'Cansat',
            heading: 'Cansat',
            url: '/cansat',
        },
        {
            src: '/Home images/row_2/IOT.jpg',
            alt: 'Internet Of Things',
            heading: 'Internet Of Things',
            url: '/IOT',
        },
        {
            src: '/Home images/row_2/Robotics.jpg',
            alt: 'Robotics',
            heading: 'Robotics',
            url: '/robotics',
        },
        {
            src: '/Home images/row_2/Advanced Robotics.jpg',
            alt: 'Advanced Robotics',
            heading: 'Advanced Robotics',
            url: '/advanced-robotics',
        },
        {
            src: '/Home images/row_2/Coding.jpg',
            alt: 'Coding',
            heading: 'Coding',
            url: '/coding',
        },
        {
            src: '/Home images/row_3/ML.jpg',
            alt: 'Machine Learning',
            heading: 'Machine Learning',
            url: '/machine-learning',
        },
        {
            src: '/Home images/row_3/AI.jpg',
            alt: 'Artificial Intelligence',
            heading: 'Artificial Intelligence',
            url: '/artificial-intelligence',
        },
        {
            src: '/Home images/row_3/DIY Projects.jpg',
            alt: 'DIY Projects',
            heading: 'DIY Projects',
            url: '/diy-projects',
        },
        {
            src: '/Home images/row_3/Data Analytics.jpg',
            alt: 'Data Analytics',
            heading: 'Data Analytics',
            url: '/data-analytics',
        },

        // {
        //     src: '/Projects/Project_Images/Automated Plant watering System.jpg',
        //     alt: 'Automated plant water system',
        //     heading: 'Automated plant water system',
        // },
        // {
        //     src: '/Projects/Project_Images/Ultrasonic radar system.jpg',
        //     alt: 'Ultrasonic radar system for object detection',
        //     heading: 'Ultrasonic radar system for object detection',
        // },
        // {
        //     src: '/Projects/Project_Images/Voice controlled.jpg',
        //     alt: 'Voice controlled Home automation',
        //     heading: 'Voice controlled Home automation',
        // },
        // {
        //     src: '/Projects/Project_Images/Lot weather station project.jpg',
        //     alt: 'Lot weather station project',
        //     heading: 'Lot weather station project',
        // },
        // {
        //     src: '/Projects/Project_Images/pet feeder.jpg',
        //     alt: 'Automatic pet feeder',
        //     heading: 'Automatic pet feeder',
        // },
        // {
        //     src: '/Projects/Project_Images/face emotion detection.jpg',
        //     alt: 'Facial emotion recognition',
        //     heading: 'Facial emotion recognition',
        // },
        // {
        //     src: '/Projects/Project_Images/Air pollution monitoring system.jpg',
        //     alt: 'Air pollution monitoring system',
        //     heading: 'Air pollution monitoring system',
        // },
        // {
        //     src: '/Projects/Project_Images/Smart cleaning robot.jpg',
        //     alt: 'Smart cleaning robot using Arduino',
        //     heading: 'Smart cleaning robot using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/heart rate.jpg',
        //     alt: 'Heartrate monitoring system',
        //     heading: 'Heartrate monitoring system',
        // },
        // {
        //     src: '/Projects/Project_Images/Smart dustbin using Arduino.jpg',
        //     alt: 'Smart dustbin using Arduino',
        //     heading: 'Smart dustbin using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/Line following car.jpg',
        //     alt: 'Line following car',
        //     heading: 'Line following car',
        // },
        // {
        //     src: '/Projects/Project_Images/gesture control car.jpg',
        //     alt: 'Gesture control car',
        //     heading: 'Gesture control car',
        // },
        // {
        //     src: '/Projects/Project_Images/Solar tracker using Arduino.jpg',
        //     alt: 'Solar tracker using Arduino',
        //     heading: 'Solar tracker using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/Object detection using.jpg',
        //     alt: 'Object detection using computer vision',
        //     heading: 'Object detection using computer vision',
        // },
        // {
        //     src: '/Projects/Project_Images/Arduino motion detector.jpg',
        //     alt: 'Arduino motion detector',
        //     heading: 'Arduino motion detector',
        // },
        // {
        //     src: '/Projects/Project_Images/Mini piano using Arduino.jpg',
        //     alt: 'Mini piano using Arduino',
        //     heading: 'Mini piano using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/Home work writing.jpg',
        //     alt: 'Home work writing machine XY plotter',
        //     heading: 'Home work writing machine XY plotter',
        // },
        // {
        //     src: '/Projects/Project_Images/Fire alarm detection and.jpg',
        //     alt: 'Fire alarm detection and temperature monitoring',
        //     heading: 'Fire alarm detection and temperature monitoring',
        // },
        // {
        //     src: '/Projects/Project_Images/Automated Plant watering System.jpg',
        //     alt: 'Plant watering system',
        //     heading: 'Plant watering system',
        // },
        // {
        //     src: '/Projects/Project_Images/two legged robot.webp',
        //     alt: 'Two legged walking robot',
        //     heading: 'Two legged walking robot',
        // },
        // {
        //     src: '/Projects/Project_Images/Obstacle avoidance car.jpg',
        //     alt: 'Obstacle avoidance car',
        //     heading: 'Obstacle avoidance car',
        // },
        // {
        //     src: '/Projects/Project_Images/fooo.jpg',
        //     alt: 'Food delivery robot using Arduino',
        //     heading: 'Food delivery robot using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/alert system.jpg',
        //     alt: 'Security alert system',
        //     heading: 'Security alert system',
        // },
        // {
        //     src: '/Projects/Project_Images/Water level indicator.jpg',
        //     alt: 'Water level indicator',
        //     heading: 'Water level indicator',
        // },
        // {
        //     src: '/Projects/Project_Images/Rain alert system.jpg',
        //     alt: 'Rain alert system',
        //     heading: 'Rain alert system',
        // },
        // {
        //     src: '/Projects/Project_Images/gas detector.jpg',
        //     alt: 'Gas leakage detector',
        //     heading: 'Gas leakage detector',
        // },
        // {
        //     src: '/Projects/Project_Images/traffic light controller.jpg',
        //     alt: 'Traffic light controller',
        //     heading: 'Traffic light controller',
        // },
        // {
        //     src: '/Projects/Project_Images/Automated street light.jpg',
        //     alt: 'Automated street light based on sunlight',
        //     heading: 'Automated street light based on sunlight',
        // },
        // {
        //     src: '/Projects/Project_Images/smart Car parking.jpg',
        //     alt: 'Smart parking system using Arduino',
        //     heading: 'Smart parking system using Arduino',
        // },
        // {
        //     src: '/Projects/Project_Images/RFID.jpg',
        //     alt: 'RFID based smart door lock',
        //     heading: 'RFID based smart door lock',
        // },

    ]

    return (
        <div>
            <div>
                {projectscover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid' />
                ))}
            </div>
            <div className='pt-5'>
                {/* <Container>
                    <Row>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                {projectscover.map((image, index) => (
                                    <div className="p-3 border bg-light">
                                        <img key={index} src={image.src} alt={image.alt} className='img-fluid our_products_cover' />
                                        <h1>{image.heading}</h1>
                                    </div>
                                ))}
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 2</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 3</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 4</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 5</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 6</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 7</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 8</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 1</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 2</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 3</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 4</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 5</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 6</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 7</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 8</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 1</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 2</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 3</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 4</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 5</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 6</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 7</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 8</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 1</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 2</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 3</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 4</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 5</div>
                            </center>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4} className="mb-4">
                            <center>
                                <div className="p-3 border bg-light">Column 6</div>
                            </center>
                        </Col>
                    </Row>
                </Container> */}
                <Container>
                    <Row>
                        {projectsimg.map((image, index) => (
                            <ImageColumn
                                key={index}
                                src={image.src}
                                alt={image.alt}
                                heading={image.heading}
                                url={image.url} // Assuming 'image.url' contains the unique URL for each image
                            />
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    )
}
