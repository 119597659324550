import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function SchoolProgramsCarousel2() {

    const SchoolProgramscover = [

        {
            src: '/School Programs Carousel/Carousel_2/Introduction to Artificial Intelligence.png',
            alt: 'Introduction to Artificial Intelligence',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Machine Learning  Basics.png',
            alt: 'Machine Learning Basics',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Data Science & Analytics.png',
            alt: 'Data Science & Analytics',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Python for AI & ML.png',
            alt: 'Python for AI & ML',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Neural Networks & Deep Learning.png',
            alt: 'Neural Networks & Deep Learning',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Natural Language Processing.png',
            alt: 'Natural Language Processing',
        },
        {
            src: '/School Programs Carousel/Carousel_2/AI Ethics & Applications.png',
            alt: 'AI Ethics & Applications',
        },
        {
            src: '/School Programs Carousel/Carousel_2/Hands-on Projects in AI & ML.png',
            alt: 'Hands-on Projects in AI & ML',
        },

    ];

    return (
        <Carousel>
            {SchoolProgramscover.map((image, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={image.src} alt={image.alt} className='img-fluid' style={{ marginRight: '20px' }} />
                        {/* <Carousel.Caption style={{ flex: '1' }}>
                            <h3>{image.title}</h3>
                            <p>{image.description}</p>
                        </Carousel.Caption> */}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SchoolProgramsCarousel2;