import React from 'react'
import './ProjectPages.css'

export default function TwoLeggedWalkingRobot() {

    const TwoLeggedWalkingRobotcover = [
        {
            src: '/Projects/Projects/Advanced Robotics.jpg',
            alt: 'TwoLeggedWalkingRobot',
        },
    ]

    return (
        <div>
            <div>
                {TwoLeggedWalkingRobotcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Two-legged Walking Robot</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Construct a bipedal walking robot, delving into mechanics and control algorithms to achieve stable locomotion.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Two-Legged Walking Robot project focuses on developing a bipedal robot capable of stable locomotion. It integrates mechanical design, servo motors, and control algorithms to achieve dynamic balance and coordinated movement. This project explores concepts in robotics, biomechanics, and feedback control. The robot&#39;s capabilities may include  walking, turning, and navigating various terrains.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Research Advancement</b><br />
                                Research into bipedal movement is essential for the
                                development of prostheses, exoskeletons, and humanoid robots. </p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Development of Skills</b><br />
                                Working on a walking robot fosters collaboration and project
                                management skills while improving knowledge of robotics, mechanical design, and
                                control systems. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Two-Legged Walking Robot</span> project </p>
                        <p>has a wide future scope and great potential in many different fields.</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Robotics</b></span></p>
                    <p className='pt-2'>It can work as a basis for further investigation into artificial intelligence, human-robot interaction, and humanoid robotics.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Healthcare and Rehabilitation</b></span></p>
                    <p className='pt-2'>The project insights may result in advancements in assistive robotics, which would benefit people with mobility issues and improve rehabilitation procedures.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Entertainment and Service sectors</b></span></p>
                    <p className='pt-2'>By improving user experience and engagement,walking robots can be used in the entertainment and service sectors for things like theme park attractions and customer service robots.</p>
                </div>
            </div>
        </div>
    )
}
