import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function SchoolProgramsCarousel3() {

    const SchoolProgramscover = [

        {
            src: '/School Programs Carousel/Carousel_3/DIY Electronics Projects.png',
            alt: 'DIY Electronics Projects',
        },
        {
            src: '/School Programs Carousel/Carousel_3/Robotics.png',
            alt: 'Robotics',
        },
        {
            src: '/School Programs Carousel/Carousel_3/Advanced Robotics.png',
            alt: 'Advanced Robotics',
        },
        {
            src: '/School Programs Carousel/Carousel_3/IoT Projects.png',
            alt: 'IoT Projects',
        },
        {
            src: '/School Programs Carousel/Carousel_3/AR & VR Tech.png',
            alt: 'AR & VR Tech',
        },
        {
            src: '/School Programs Carousel/Carousel_3/Computer Vision & Python.png',
            alt: 'Computer Vision & Python',
        },

    ];

    return (
        <Carousel>
            {SchoolProgramscover.map((image, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={image.src} alt={image.alt} className='img-fluid' style={{ marginRight: '20px' }} />
                        {/* <Carousel.Caption style={{ flex: '1' }}>
                            <h3>{image.title}</h3>
                            <p>{image.description}</p>
                        </Carousel.Caption> */}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SchoolProgramsCarousel3;