import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function SchoolProgramsCarousel1() {

    const SchoolProgramscover = [

        {
            src: '/School Programs Carousel/Carousel_1/Glider.png',
            alt: 'Gliders',
        },
        {
            src: '/School Programs Carousel/Carousel_1/Drones.png',
            alt: 'Drones',
        },
        {
            src: '/School Programs Carousel/Carousel_1/RC Plane.png',
            alt: 'RC Plane',
        },
        {
            src: '/School Programs Carousel/Carousel_1/CanSat.png',
            alt: 'CanSat',
        },
        {
            src: '/School Programs Carousel/Carousel_1/CAD.png',
            alt: 'CAD',
        },
        {
            src: '/School Programs Carousel/Carousel_1/Flapping UAV.png',
            alt: 'Flapping UAV',
        },

    ];

    return (
        <Carousel>
            {SchoolProgramscover.map((image, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={image.src} alt={image.alt} className='img-fluid' style={{ marginRight: '20px' }} />
                        {/* <Carousel.Caption style={{ flex: '1' }}>
                            <h3>{image.title}</h3>
                            <p>{image.description}</p>
                        </Carousel.Caption> */}
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default SchoolProgramsCarousel1;


// import React from 'react';
// import { Carousel, Container, Row, Col } from 'react-bootstrap';
// import './SchoolProgramsCarousel1.css';

// const SchoolProgramsCarousel1 = () => {
//   const items = [
//     {
//       src: '/School Programs Carousel/PROJECTS COVER 1.png',
//       caption: 'Caption 1',
//       description: 'Description for image 1'
//     },
//     {
//       src: '/School Programs Carousel/PROJECTS COVER 1.png',
//       caption: 'Caption 2',
//       description: 'Description for image 2'
//     },
//     {
//       src: '/School Programs Carousel/PROJECTS COVER 1.png',
//       caption: 'Caption 3',
//       description: 'Description for image 3'
//     }
//   ];

//   return (
//     <Carousel>
//       {items.map((item, index) => (
//         <Carousel.Item key={index}>
//           {/* <Container> */}
//             <Row className="align-items-center">
//               <Col md={12}>
//                 <img
//                   className="d-block w-100"
//                   src={item.src}
//                   alt={`Slide ${index}`}
//                 />
//               </Col>
//               <Col md={6}>
//                 <div className="carousel-caption">
//                   <h3>{item.caption}</h3>
//                   <p>{item.description}</p>
//                 </div>
//               </Col>
//             </Row>
//           {/* </Container> */}
//         </Carousel.Item>
//       ))}
//     </Carousel>
//   );
// };

// export default SchoolProgramsCarousel1;
