import React from 'react'
import './ProjectPages.css'

export default function Artificialintelligenceproject() {

    const Artificialintelligenceprojectcover = [
        {
            src: '/Projects/Projects/AI (1).jpg',
            alt: 'Artificialintelligenceproject',
        },
    ]

    return (
        <div>
            <div>
                {Artificialintelligenceprojectcover.map((image, index) => (
                    <img key={index} src={image.src} alt={image.alt} className='img-fluid w-100' />
                ))}
            </div>
            <div className='container text-center projects_page_description'>
                <div className='pt-5 projects_page_heading'>
                    <h1><b>Artificial Intelligence Project</b></h1>
                </div>
                <div className='pt-1'>
                    <p>Explore the realm of artificial intelligence, learning about machine learning algorithms and
                        neural networks to develop intelligent systems.</p>
                </div>
                <div className='pt-4 px-5'>
                    <p>The Artificial Intelligence project delves into the development of intelligent systems capable
                        of simulating human-like cognition and decision-making. It explores machine learning
                        algorithms, natural language processing, and computer vision techniques. Applications span
                        diverse domains, including virtual assistants, autonomous vehicles, and personalized
                        recommendation systems. This project advances understanding in AI research and its
                        practical applications.</p>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_benefits'>
                    <center>
                        <h1>Student's <span><b>Benefits</b></span><div><h5>from our projects.</h5></div></h1>
                    </center>
                </div>
            </div>
            {/* <div className='container pt-5'>
                <div className='flex'>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_pink_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                    <div className='container projects_blue_stickynote'>
                        <div className='container'>
                            <p><b>Educational Value</b><br />
                                It is a great teaching tool
                                that provides real-world
                                understanding of control
                                theory, computer science,
                                engineering fundamentals
                                and Biomechanics. </p>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='container pt-5'>
                <div className='d-flex flex-wrap justify-content-center'>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Educational Value</b><br />
                                It gives students real-world experience in computer science,
                                math, and engineering, and is a great teaching tool.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_pink_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Engagement of Hobbyists</b><br />
                                Developing and implementing AI models is an enjoyable
                                pastime that fosters technical proficiency and inventiveness.</p>
                        </div>
                    </div>
                    <div className='projects_page_note projects_blue_stickynote'>
                        <div className='projects_page_content'>
                            <p><b>Skill Development</b><br />
                                Working on AI projects helps develop programming skills, an
                                enhanced grasp of data analysis, collaboration, and project management ability.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container pt-5'>
                <div className='pt-5 projects_page_potential_scope'>
                    <center><h1><span>Potential</span> <b>Scope.</b></h1></center>
                </div>
                <div className='pt-5 potential_scope'>
                    <center>
                        <p>The <span>Artificial Intelligence</span> project </p>
                        <p>offers a wide range of potential uses in numerous industries in the future.</p>
                    </center>
                </div>
            </div>
            <div className='container pt-5 px-5 py-5'>
                <div className='container pt-3 px-5 potential_scope_1'>
                    <p><span><b>Advanced Research:</b></span></p>
                    <p className='pt-2'>It can be the foundation for state-of-the-art investigations into
                        AI, machine learning, and data science.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_2'>
                    <p><span><b>Innovation in AI Solutions</b></span></p>
                    <p className='pt-2'>Information from AI projects can be used to develop
                        more sophisticated AI solutions for healthcare, finance, and other industries.</p>
                </div>
                <div className='container pt-3 px-5 potential_scope_3'>
                    <p><span><b>Advancement in Education</b></span></p>
                    <p className='pt-2'>As AI projects continue to grow, more comprehensive
                        curriculum that educate students for careers in AI and related industries might be
                        developed.</p>
                </div>
            </div>
        </div>
    )
}
