import React from 'react'
import './schoolimgmarquee.css'
import { Link } from 'react-router-dom'

export default function SchoolimgMarquee({ images }) {
    return (
        <div className='marqueeleft-container'>
            <div className='schoolimg_marqueeleft pt-5'>
                {images.map((image, index) => (
                    <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4'>
                        <div className={image.cardcolour}>
                            <center>
                                <Link to='/gallery' className='text-decoration-none'>
                                    <img src={image.src} alt={image.alt} className='card-img-top img-fluid school_image' />
                                    <div className='card-body' id='school_names'>
                                        <p className='school_name'>{image.school_name}</p>
                                        {/* <p className='card-text'>{image.school_intro}</p> */}
                                    </div>
                                </Link>
                            </center>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
