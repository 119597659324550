import React, { useState } from 'react' 
import { IoMdArrowDropdown,IoMdArrowDropup } from "react-icons/io";

const StemandRoboticsFAQ = () => {
    const[selected,setselected] = useState(null); 
     
    const toggle = (i) =>{ 
        if(selected == i){ 
            return setselected(null);
        } 
        setselected(i);
        
    }

    const data = [
        {
            "question": "1. What types of projects are included in your STEM & Robotics programs?",
            "answer": "Our projects include building and programming robots, designing engineering challenges, and exploring practical learning experiments in various STEM fields."
        },
        {
            "question": "2. Are your STEM & Robotics projects suitable for all ages?",
            "answer": "Yes, we design projects that cater to different age groups and skill levels, from elementary students to high schoolers."
        },
        {
            "question": "3. What skills will my child learn from these projects?",
            "answer": "Students will learn about coding, the fundamentals of robotics, and the importance of STEM & Robotics. They will also develop critical thinking, problem-solving, teamwork, and technical skills such as coding and engineering design."
        },
        {
            "question": "4. How are the projects structured?",
            "answer": "Projects are structured to provide a mix of introductory theoretical knowledge and practical learning applications, often culminating in a final challenge."
        },
        {
            "question": "5. Do you offer any online resources for the projects?",
            "answer": "Yes, we provide online resources for those who purchase our kits. They will receive access to the user manuals, project guides, and support forums to help them succeed."
        },
        {
            "question": "6. Can students work on projects in groups?",
            "answer": "Absolutely, our projects encourage collaborative work, allowing students to work in teams to solve problems and complete tasks."
        },
        {
            "question": "7. Are there any competitions associated with the projects?",
            "answer": "Yes, we organize competitions and showcases where students can present their projects and compete with their peers."
        },
        {
            "question": "8. What materials are needed for the projects?",
            "answer": "All necessary materials and tools are provided as part of the project. Students do not need to purchase additional supplies. If required, they can buy our customized kits from our product page."
        },
        {
            "question": "9. What grades can apply for this course?",
            "answer": "Our courses are designed for students from elementary school to high school, ensuring appropriate content and challenge levels for each age group."
        },
        {
            "question": "10. What are the benefits for my child from this STEM & Robotics program?",
            "answer": "In today's world, technology is rapidly advancing and becoming integral to our daily lives. Through this course, your child will learn about new technologies and gain practical learning experiences. Additionally, students will be inspired and motivated by the hands-on nature of the projects."
        },
        {
            "question": "11. Can workshops be customized for specific groups or schools?",
            "answer": "Yes, we offer customized workshops tailored to the specific needs and interests of different groups and schools."
        },
        {
            "question": "12. How can I find the schedule for upcoming workshops?",
            "answer": "The schedule for upcoming workshops is available on our website. You can also sign up for our newsletter to receive updates."
        }
    ]
    ;

    return (
        <section className='section'>
            <div className="container"> 
               <h2 className='title text-center mb-5'>Frequently Asked Questions (FAQs)</h2> 
             <div className='mx-lg-5'>
                {data.map((item, i) => (
                    <div className='mb-2 rounded-2 px-4 py-2 mx-lg-5 border border-2 cursor-pointer' style={{backgroundColor:'#fff' ,color: '#0a58ca'}} > 
                        <div className='d-flex justify-content-between align-items-center p-1 ' onClick={()=>toggle(i)}>
                            <h3 className='h5'>{item.question}</h3> 
                            <span className='fs-4'>{selected === i ? <IoMdArrowDropup/> : <IoMdArrowDropdown />}</span>
                        </div>
                        <div className={selected === i ? 'content show' : 'content'}>
                            <p className='text px-2'>{item.answer}</p>
                        </div>
                    </div>
                ))}
             </div> 
          </div>
        </section>
    )
}

export default StemandRoboticsFAQ;
