import React, { useState } from 'react'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const AIandMLfaq = () => {
    const [selected, setselected] = useState(null);

    const toggle = (i) => {
        if (selected == i) {
            return setselected(null);
        }
        setselected(i);

    }

    const data = [
        {
            question: '1. What will students learn in your AI & Machine Learning courses?',
            answer: 'Students will learn the basics of artificial intelligence, machine learning algorithms, data analysis, and how to apply these concepts to real-world problems.'
        },

        {
            question: '2. Are the courses suitable for beginners?',
            answer: 'Yes, our courses are designed for all skill levels, with introductory sessions for beginners and advanced modules for experienced learners.'
        },

        {
            question: '3. What programming languages are taught in these courses?',
            answer: 'Our courses typically focus on programming languages commonly used in AI and ML, such as Python and R.'
        },

        {
            question: '4. Do you provide any hands-on projects in these courses?',
            answer: 'Yes, students engage in hands-on projects that involve building AI models, working with datasets, and creating machine learning applications.'
        },

        {
            question: '5. How long is a typical AI & Machine Learning course? ',
            answer: 'Courses vary in length from short workshops to semester-long classes, depending on the depth of the subject matter.'
        },

        {
            question: '6. Who are the instructors for these courses? ',
            answer: ' Our instructors are industry professionals and academic experts in AI and machine learning with extensive teaching experience.'
        },

        {
            question: '7. Are there any prerequisites for joining the courses?',
            answer: 'Basic programming knowledge is recommended for most courses, but not required. Introductory courses are available for complete beginners.'
        },

        {
            question: '8. Can the courses be customized for specific needs? ',
            answer: 'Yes, we offer customized courses tailored to the needs of schools, groups, or organizations.'
        },

        {
            question: '9. How can I find the schedule for upcoming courses? ',
            answer: ' The schedule for upcoming courses is listed on our website. You can also subscribe to our newsletter for the latest updates.'
        },

        {
            question: '10. What is the cost of participating in an AI & Machine Learning course? ',
            answer: 'The cost varies depending on the course duration and content. Detailed pricing information is available on our website.'
        },

    ];

    return (
        <section className='section'>
            <div className="container">
                <h2 className='title text-center mb-5'>Frequently Asked Questions (FAQs)</h2>
                <div className='mx-lg-5'>
                    {data.map((item, i) => (
                        <div className='mb-2 rounded-2 px-4 py-2 mx-lg-5 border border-2 cursor-pointer' style={{ backgroundColor: '#fff', color: '#0a58ca' }} >
                            <div className='d-flex justify-content-between align-items-center p-1 ' onClick={() => toggle(i)}>
                                <h3 className='h5'>{item.question}</h3>
                                <span className='fs-4'>{selected === i ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
                            </div>
                            <div className={selected === i ? 'content show' : 'content'}>
                                <p className='text px-2'>{item.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AIandMLfaq;
