import React, { useEffect } from 'react'
import './footer.css'

export default function Footer() {
  return (
    // <div>
    //   <footer className="footer bg-black text-white py-3">
    //     <center>
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-md">
    //             <a href="/products" className="text-white footer_titles">Products</a>
    //           </div>
    //           <div className="col-md">
    //             <a href="/school-programs" className="text-white footer_titles">School Programs</a>
    //           </div>
    //           <div className="col-md">
    //             <a href="/lab" className="text-white footer_titles">Lab</a>
    //           </div>
    //           <div className="col-md">
    //             <a href="/projects" className="text-white footer_titles">Projects</a>
    //           </div>
    //           <div className="col-md">
    //             <a href="/shop" className="text-white footer_titles">Shop</a>
    //           </div>
    //           {/* <div className="col-md">
    //             <a href="/blog" className="text-white footer_titles">Blog</a>
    //           </div> */}
    //         </div>
    //       </div>
    //     </center>
    //   </footer>
    // </div>
    // <div>
    //   <footer className="footer bg-black text-white py-3">
    //     <center>
    //       <div className="container d-flex">
    //         <div className="col">
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/products" className="text-white footer_titles">Products</a>
    //           </div>
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/school-programs" className="text-white footer_titles">School Programs</a>
    //           </div>
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/lab" className="text-white footer_titles">Lab</a>
    //           </div>
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/projects" className="text-white footer_titles">Projects</a>
    //           </div>
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/shop" className="text-white footer_titles">Shop</a>
    //           </div>
    //           <div className="col-md mb-3 mb-md-0 text-start">
    //             <a href="/our-governance" className="text-white footer_titles">Our Governance</a>
    //           </div>
    //         </div>
    //         <div className='col-md mb-3 mb-md-0 text-start'>
    //           <h2>Contact Us</h2>
    //           <p>Arunachala Raam - Project Head<br />
    //             <b>Ph:</b> +91 97910 72916<br />
    //             <b>Mail:</b> arunraam@mhcockpit.com
    //           </p>
    //         </div>
    //         <div className='text-start'>
    //           <h2>Corporate Office</h2>
    //           <p>Featherlite building, 200 Feet Radial Rd,<br />
    //             Iswarya Nagar, Raja J Joseph Colony<br />
    //             Pallavaram, Chennai - 600043.</p>
    //         </div>
    //       </div>
    //         <div className='pt-3'>
    //           <p>Copyright © 2024 MH Intellect. All rights reserved.</p>
    //         </div>
    //     </center>
    //   </footer>
    // </div>
    <div>
      <footer className="footer bg-black text-white py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4 mb-3">
              <div className="text-start">
                <a href="/products" className="text-white footer_titles">Products</a>
              </div>
              <div className="text-start">
                <a href="/school-programs" className="text-white footer_titles">School Programs</a>
              </div>
              <div className="text-start">
                <a href="/lab" className="text-white footer_titles">Lab</a>
              </div>
              <div className="text-start">
                <a href="/projects" className="text-white footer_titles">Projects</a>
              </div>
              <div className="text-start">
                <a href="/" className="text-white footer_titles">Shop</a>
              </div>
              <div className="text-start">
                <a href="/our-governance" className="text-white footer_titles">Our Governance</a>
              </div>
              <div className="text-start">
                <a href="/gallery" className="text-white footer_titles">Gallery</a>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <h2>Regional Office</h2>
              <p>
                MHB Applied Studies L.L.C<br />
                Dubai, UAE<br />
                <b>Mail: </b>info@mhintellect.com
              </p>
              {/* <p>Arunachala Raam - Project Head<br />
                <b>Ph:</b> +91 97910 72916<br />
                <b>Mail:</b> arunraam@mhcockpit.com
              </p> */}
            </div>
            <div className="col-12 col-md-4 mb-3">
              <h2>Corporate Office</h2>
              <p>
                Featherlite The Address<br />
                Chennai, India<br />
                <b>Mail: </b>info@mhintellect.com
              </p>
              {/* <p>Featherlite building, 200 Feet Radial Rd,<br />
                Iswarya Nagar, Raja J Joseph Colony<br />
                Pallavaram, Chennai - 600043.</p> */}
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-12 text-center">
              <p>Copyright © 2024 MH Intellect. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </div>

  )
}
